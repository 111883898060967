import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
import { getCertificates } from "../services/CertificateService";
import { getAccessToken } from "../utils/http";
import { InputText } from "primereact/inputtext";
import { showErrorToast, showErrorToastNullable } from "../services/ToastService";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { viewCertificatePDFs, viewTestCertificatePDFs } from "../services/DashboardService";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { saveAs } from "file-saver";
import { Button } from "primereact/button"; // Make sure this import is correct
import moment from "moment";
import { TabPanel, TabView } from "primereact/tabview";

export default function SurveyList() {
  const token = getAccessToken(); 
  const toast = useRef(null);
  const dt = useRef(null);
  const [data, setData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [statusFilterValue, setStatusFilterValue] = useState("false");
  const [PFASFilterValue, setPFASFilterValue] = useState("");
  const [PFASTestFilterValue, setPFASTestFilterValue] = useState("");
  const [getAllData, setGetAllData] = useState("false");
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: [
      {
        type: "global",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: null
      },
      {
        type: "column",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: "IsPFASEnabled"
      },
      {
        type: "column",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: "IsPFASTestEnabled"
      }
    ]
  });
  const mdt = useRef(null);
  const [mData, setMData] = useState([]);
  const [mGlobalFilterValue, setMGlobalFilterValue] = useState("");
  const [mTotalRecords, setMTotalRecords] = useState(0);
  const [mIsLoading, setMIsLoading] = useState(false);
  const [mStatusFilterValue, setMStatusFilterValue] = useState("false");
  const [mPFASFilterValue, setMPFASFilterValue] = useState("");
  const [mPFASTestFilterValue, setMPFASTestFilterValue] = useState("");
  const [mGetAllData, setMGetAllData] = useState("false");
  const [mLazyState, setMLazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: [
      {
        type: "global",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: null
      },
      {
        type: "column",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: "IsPFASEnabled"
      },
      {
        type: "column",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: "IsPFASTestEnabled"
      }
    ]
  });

  const onPFASFilterChange = (e) => {
    setPFASFilterValue(e.value);
    let value = e.value === "" ? [] : [e.value];
    setLazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "IsPFASEnabled").value = value;
      return state;
    });
  };

  const onMPFASFilterChange = (e) => {
    setMPFASFilterValue(e.value);
    let value = e.value === "" ? [] : [e.value];
    setMLazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "IsPFASEnabled").value = value;
      return state;
    });
  };

  const AddPFASTemplate = (data) => {
    switch (data.IsPFASEnabled) {
      case 0:
        return "No to All products";
      case 1:
        return "Yes to All products";
      case 2:
        return "Yes to Some products";
      default: 
        return "";
    }
  };

  const PFASTestTemplate = (data) => {
    return data.IsPFASTestEnabled === true ? "Yes" : data.IsPFASTestEnabled === false ? "No" : "";
  };

  const onPFASTestFilterChange = (e) => {
    setPFASTestFilterValue(e.value);
    let value = e.value === "" ? [] : [e.value];
    setLazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "IsPFASTestEnabled").value = value;
      return state;
    });
  };
  
  const onMPFASTestFilterChange = (e) => {
    setMPFASTestFilterValue(e.value);
    let value = e.value === "" ? [] : [e.value];
    setMLazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "IsPFASTestEnabled").value = value;
      return state;
    });
  };

  const loadCertificates = () => {
    setIsLoading(true);
    const body = { ...lazyState, GetAllData: getAllData, IdType: "supplier" };
    getCertificates(body, token, (res) => {
      // Transform response data to include both PFAS and PFASTest certificates in a single array
      console.log(res.data.Items); // Check if SupplierGroupName is present in the response
      const updatedData = res?.data?.Items.map(item => ({
        ...item,
        PFASFiles: item.PFASCertificateDetails.filter(cert => cert.PFASFileName),
        PFASTestFiles: item.PFASCertificateDetails.filter(cert => cert.PFASTestFileName)
      }));
      setData(updatedData);
      setTotalRecords(res?.data?.TotalRecords);
      setIsLoading(false);
    },
    (error) => {
      showErrorToast(toast, "Error loading certificates");
      setIsLoading(false);
    })
  }

  const loadMCertificates = () => {
    setMIsLoading(true);
    const body = { ...mLazyState, GetAllData: mGetAllData, IdType: "manufacturer" };
    getCertificates(body, token, (res) => {
      const updatedData = res?.data?.Items.map(item => ({
        ...item,
        PFASFiles: item.PFASCertificateDetails.filter(cert => cert.PFASFileName),
        PFASTestFiles: item.PFASCertificateDetails.filter(cert => cert.PFASTestFileName)
      }));
      setMData(updatedData);
      setMTotalRecords(res?.data?.TotalRecords);
      setMIsLoading(false);
    },
    (error) => {
      showErrorToast(toast, "Error loading certificates");
      setMIsLoading(false);
    })
  }

  useEffect(() => {
    loadCertificates();
  }, [lazyState, getAllData]);

  useEffect(() => {
    loadMCertificates();
  }, [mLazyState, mGetAllData]);
  
  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
  };

  const onGlobalFilterEnter = (e) => {
    const value = e.target.value;
    if (e.key === "Enter") {
      setLazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "global").value = [value];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  };
  
  const onGlobalFilterBlur = (e) => {
    const value = e.target.value;
    const prevGlobalFilterValue = lazyState.filters.find(f => f.type === "global").value[0];
    if (value === "" && !!prevGlobalFilterValue && value !== prevGlobalFilterValue) {
      setLazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "global").value = [];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  };

  const onMGlobalFilterChange = (e) => {
    setMGlobalFilterValue(e.target.value);
  };

  const onMGlobalFilterEnter = (e) => {
    const value = e.target.value;
    if (e.key === "Enter") {
      setMLazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "global").value = [value];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  };
  
  const onMGlobalFilterBlur = (e) => {
    const value = e.target.value;
    const prevGlobalFilterValue = mLazyState.filters.find(f => f.type === "global").value[0];
    if (value === "" && !!prevGlobalFilterValue && value !== prevGlobalFilterValue) {
      setMLazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "global").value = [];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  };

  const onStatusFilterChange = (e) => {
    const value = e.value;
    setStatusFilterValue(value);
    setGetAllData(value === "true" ? "true" : "false");
  };

  const onMStatusFilterChange = (e) => {
    const value = e.value;
    setMStatusFilterValue(value);
    setMGetAllData(value === "true" ? "true" : "false");
  };

  const onPage = (event) => {
    setLazyState(prev => ({
      ...prev,
      first: event.first,
      rows: event.rows,
      page: event.page
    }));
  };

  const onSort = (event) => {
    setLazyState(prev => ({
      ...prev,
      sortField: event.sortField,
      //sortOrder: event.sortField == "PFASFileName"? -1 : event.sortOrder
      sortOrder: event.sortOrder
    }));
  };

  const onFilter = (event) => {
    setLazyState(prev => ({
      ...prev,
      filters: event.filters
    }));
  };

  const onMPage = (event) => {
    setMLazyState(prev => ({
      ...prev,
      first: event.first,
      rows: event.rows,
      page: event.page
    }));
  };

  const onMSort = (event) => {
    setMLazyState(prev => ({
      ...prev,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    }));
  };

  const onMFilter = (event) => {
    setMLazyState(prev => ({
      ...prev,
      filters: event.filters
    }));
  };

  const statusFilterOptions = [
    { text: "All", value: "true" },
    { text: "Submitted", value:"false" },
  ];

  const PFASFilterOptions = [
    { text: "All", value: ""},
    { text: "Yes to All products", value: 1},
    { text: "Yes to Some products", value: 2},
    { text: "No to All products", value: 0}
  ];

  const PFASTestFilterOptions = [
    { text: "All", value: ""},
    { text: "Yes", value: true},
    { text: "No", value: false}
  ];

  const exportCSV = () => {
    const body = { ...lazyState, GetAllData: getAllData, first: 0, page: 0, rows: totalRecords, IdType: "supplier" };
    getCertificates(body, token, (res) => {
      const updatedData = res?.data?.Items.map(item => ({
        ...item,
        PFASFiles: item.PFASCertificateDetails.filter(cert => cert.PFASFileName),
        PFASTestFiles: item.PFASCertificateDetails.filter(cert => cert.PFASTestFileName)
      }));
      convertToCSV(updatedData);
    },
    (error) => {
      showErrorToast(toast, "Error loading certificates");
    });
  };

  const mExportCSV = () => {
    const body = { ...mLazyState, GetAllData: mGetAllData, first: 0, page: 0, rows: mTotalRecords, IdType: "manufacturer" };
    getCertificates(body, token, (res) => {
      const updatedData = res?.data?.Items.map(item => ({
        ...item,
        PFASFiles: item.PFASCertificateDetails.filter(cert => cert.PFASFileName),
        PFASTestFiles: item.PFASCertificateDetails.filter(cert => cert.PFASTestFileName)
      }));
      mConvertToCSV(updatedData);
    },
    (error) => {
      showErrorToast(toast, "Error loading certificates");
    });
  };

  const convertToCSV = (surveyResponses) => {
    const exportData = surveyResponses.map(item => ({
        ...item,
        PFASFiles: item.PFASFiles.map(file => file.PFASFileName).join(', '),
        PFASTestFiles: item.PFASTestFiles.map(file => file.PFASTestFileName).join(', '),
        IsPFASEnabled: AddPFASTemplate(item),
        IsPFASTestEnabled: PFASTestTemplate(item),
    }));

    const headers = dt.current.props.children.map(col => col.props.header);
    const dataFields = dt.current.props.children.map(col => col.props.field);

    let csvContent = headers.join(',') + '\n';
    exportData.forEach(item => {
        let row = dataFields.map(field => `"${item[field] || ''}"`);
        csvContent += row.join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'export.csv');
};

  const mConvertToCSV = (surveyResponses) => {
    const exportData = surveyResponses.map(item => ({
        ...item,
        PFASFiles: item.PFASFiles.map(file => file.PFASFileName).join(', '),
        PFASTestFiles: item.PFASTestFiles.map(file => file.PFASTestFileName).join(', '),
        IsPFASEnabled: AddPFASTemplate(item),
        IsPFASTestEnabled: PFASTestTemplate(item),
    }));

    const headers = mdt.current.props.children.map(col => col.props.header);
    const dataFields = mdt.current.props.children.map(col => col.props.field);

    let csvContent = headers.join(',') + '\n';
    exportData.forEach(item => {
        let row = dataFields.map(field => `"${item[field] || ''}"`);
        csvContent += row.join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'export.csv');
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">SURVEY PAGE</h4>
      <div className="flex flex-wrap gap-2 align-items-center">
        <Button
          label="Export"
          icon="pi pi-download"
          className="p-button-help"
          style={{ backgroundColor: "#003e7e" }}
          onClick={exportCSV}
        />
        <label htmlFor="PFASFilter">Add PFAS:</label>
        <Dropdown 
          inputId="PFASFilter"
          value={PFASFilterValue}
          options={PFASFilterOptions}
          onChange={onPFASFilterChange}
          optionLabel="text"
          optionValue="value"
        />
        <label htmlFor="PFASTestFilter">Test PFAS:</label>
        <Dropdown 
          inputId="PFASTestFilter"
          value={PFASTestFilterValue}
          options={PFASTestFilterOptions}
          onChange={onPFASTestFilterChange}
          optionLabel="text"
          optionValue="value"
        />
        <label htmlFor="statusFilter">Status:</label>
        <Dropdown 
          inputId="statusFilter"
          value={statusFilterValue}
          options={statusFilterOptions}
          onChange={onStatusFilterChange}
          optionLabel="text"
          optionValue="value"
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={globalFilterValue}
            onInput={onGlobalFilterChange}
            placeholder="Search..."
            onKeyPress={onGlobalFilterEnter}
            onBlur={onGlobalFilterBlur}
          />
        </span>
      </div>
    </div>
  );

  const mHeader = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">SURVEY PAGE</h4>
      <div className="flex flex-wrap gap-2 align-items-center">
        <Button
          label="Export"
          icon="pi pi-download"
          className="p-button-help"
          style={{ backgroundColor: "#003e7e" }}
          onClick={mExportCSV}
        />
        <label htmlFor="PFASFilter">Add PFAS:</label>
        <Dropdown 
          inputId="PFASFilter"
          value={mPFASFilterValue}
          options={PFASFilterOptions}
          onChange={onMPFASFilterChange}
          optionLabel="text"
          optionValue="value"
        />
        <label htmlFor="PFASTestFilter">Test PFAS:</label>
        <Dropdown 
          inputId="PFASTestFilter"
          value={mPFASTestFilterValue}
          options={PFASTestFilterOptions}
          onChange={onMPFASTestFilterChange}
          optionLabel="text"
          optionValue="value"
        />
        <label htmlFor="statusFilter">Status:</label>
        <Dropdown 
          inputId="statusFilter"
          value={mStatusFilterValue}
          options={statusFilterOptions}
          onChange={onMStatusFilterChange}
          optionLabel="text"
          optionValue="value"
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={mGlobalFilterValue}
            onInput={onMGlobalFilterChange}
            placeholder="Search..."
            onKeyPress={onMGlobalFilterEnter}
            onBlur={onMGlobalFilterBlur}
          />
        </span>
      </div>
    </div>
  );

  const viewCertificate = (data, cert) => { 
    const supplierId = data.SupplierId;
    viewCertificatePDFs(cert.PFASFileLocation, supplierId, token, (res) => {
      const file = new Blob([res?.data], { type: res.headers['content-type'] });
      saveAs(file, cert.PFASFileName);
    },
    (e) => {
      showErrorToast(toast, "Failed to view certificate PDF.");
    });
  };

  const viewTestCertificate = (data, cert) => {
    const supplierId = data.SupplierId;
    viewTestCertificatePDFs(cert.PFASTestFileLocation, supplierId, token, (res) => {
      const file = new Blob([res?.data], { type: res.headers['content-type'] });
      saveAs(file, cert.PFASTestFileName);
    },
    (e) => {
      showErrorToast(toast, "Failed to view test certificate PDF.");
    });
  };

  const certLinkTemplate = (data) => {
    return (
      <>
        {data.PFASFiles.map((file, index) => {
          return (
            <div key={index}>
              <Link onClick={() => viewCertificate(data, file)}>
                {file.PFASFileName || 'No File Name'} {/* Use default text if property is missing */}
              </Link>
              {index < data.PFASFiles.length - 1 && ", "}
            </div>
          );
        })}
      </>
    );
  };

  const testLinkTemplate = (data) => {
    return (
      <>
        {data.PFASTestFiles.map((file, index) => (
          <div key={index}>
            <Link onClick={() => viewTestCertificate(data, file)}>
              {file.PFASTestFileName}
            </Link>
            {index < data.PFASTestFiles.length - 1 && ", "}
          </div>
        ))}
      </>
    )
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return dateString.split("T")[0];
  };

  return (
    <>
      <Toast ref={toast} />
      <TabView>
      <TabPanel header = "Supplier Survey">
      <DataTable
        ref={dt}
        value={data}
        dataKey="SupplierId"
        paginator
        rows={lazyState.rows}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} survey responses"
        header={header}
        loading={isLoading}
        lazy
        first={lazyState.first}
        totalRecords={totalRecords}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        onFilter={onFilter}
        filters={lazyState.filters}
        removableSort
        scrollable
        scrollHeight="55vh"
      >
        <Column 
            field="SupplierGroupName" 
            header="Supplier Group Name" 
            sortable
            style={{ minWidth: "12rem" }} 
        />
        <Column
            field="SupplierName"
            header="Supplier Name"
            sortable
            style={{ minWidth: "12rem" }}
        />
        <Column 
            field="IsPFASEnabled" 
            header="Add PFAS" 
            body={AddPFASTemplate} 
        />
        <Column 
            field="PFASFiles" 
            header="Certificate File Name" 
            body={certLinkTemplate} 
            sortable
        />
        <Column 
            field="IsPFASTestEnabled" 
            header="Test PFAS" 
            body={PFASTestTemplate} 
        />
        <Column 
            field="PFASTestFiles" 
            header="Test Results File Name" 
            body={testLinkTemplate} 
        />
        <Column 
            field="PFASLastUpdatedBy" 
            header="PFAS Last Updated By" 
        />
        <Column 
            field="PFASLastUpdatedDate" 
            header="PFAS Last Updated Date" 
            body={(rowData) => formatDate(rowData.PFASLastUpdatedDate)}
            style={{ minWidth: "12rem", textAlign: 'center' }}
            sortable
        />
        </DataTable>
      </TabPanel>
      <TabPanel header = "Manufacturer Survey">
      <DataTable
        ref={mdt}
        value={mData}
        dataKey="ManufacturerId"
        paginator
        rows={mLazyState.rows}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} survey responses"
        header={mHeader}
        loading={mIsLoading}
        lazy
        first={mLazyState.first}
        totalRecords={mTotalRecords}
        onPage={onMPage}
        onSort={onMSort}
        sortField={mLazyState.sortField}
        sortOrder={mLazyState.sortOrder}
        onFilter={onMFilter}
        filters={mLazyState.filters}
        removableSort
        scrollable
        scrollHeight="55vh"
      >
        <Column 
            field="ManufacturerName" 
            header="Manufacturer Name" 
            sortable
            style={{ minWidth: "12rem" }} 
        />
        <Column 
            field="IsPFASEnabled" 
            header="Add PFAS" 
            body={AddPFASTemplate} 
        />
        <Column 
            field="PFASFiles" 
            header="Certificate File Name" 
            body={certLinkTemplate} 
            sortable
        />
        <Column 
            field="IsPFASTestEnabled" 
            header="Test PFAS" 
            body={PFASTestTemplate} 
        />
        <Column 
            field="PFASTestFiles" 
            header="Test Results File Name" 
            body={testLinkTemplate} 
        />
        <Column 
            field="PFASLastUpdatedBy" 
            header="PFAS Last Updated By" 
        />
        <Column 
            field="PFASLastUpdatedDate" 
            header="PFAS Last Updated Date" 
            body={(rowData) => formatDate(rowData.PFASLastUpdatedDate)}
            style={{ minWidth: "12rem", textAlign: 'center' }}
            sortable
        />
        </DataTable>
      </TabPanel>
      </TabView>
    </>
  );
}
