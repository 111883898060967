import React, { useEffect, useRef, useState } from "react";
import { getAccessToken } from "../utils/http";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { showErrorToastNullable, showSuccessToast } from "../services/ToastService";
import { getSupplier as getSuppliersList, groupSupplier, getManufacturersList } from "../services/UserManagementService";
import { itemMappingsList } from "../services/ApproverService";
import { saveItemMappings } from "../services/ItemListService";
import { deleteStoredData } from "../utils/http";
import { useNavigate } from "react-router-dom";

export default function ItemManagement() {
    const token = getAccessToken(); 
    const toast = useRef(null);
    const idt = useRef(null);
    const [itemList, setItemList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [statusFilterValue, setStatusFilterValue] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [viewItemDialog,setViewItemDialog] = useState(false);
    const [isItemValid, setIsItemValid] = useState(false);
    const [itemNumber, setItemNumber] = useState("");
    const [supplierGroupList, setSupplierGroupList] = useState([]);
    const [selectedSupplierGroup, setSelectedSupplierGroup] = useState("");
    const [supplierList, setSupplierList] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState("");
    const [manufacturerList, setManufacturerList] = useState([]);
    const [selectedManufacturer, setSelectedManufacturer] = useState("");
    const [isItemActive, setIsItemActive] = useState(true);
    const [itemId, setItemId] = useState("");
    const [activateItemDialog, setActivateItemDialog] = useState(false);
    const [inactivateItemDialog, setInactivateItemDialog] = useState(false);
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const navigate = useNavigate();  
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 0,
      sortField: null,
      sortOrder: null,
      filters: [
        {
          type: "global",
          value: [],
          matchMode: FilterMatchMode.CONTAINS,
          field: null
        },
        {
          type: "column",
          value: [],
          matchMode: FilterMatchMode.CONTAINS,
          field: "IsActive"
        }
      ]
    });
  
    const statusFilterOptions = [
      { text: "All", value: "" },
      { text: "Yes", value: true},
      { text: "No", value: false},
    ];

    const onStatusFilterChange = (e) => {
      setStatusFilterValue(e.value);
      let value = e.value === "" ? [] : [e.value];
      setlazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "column" && f.field === "IsActive").value = value;
        return state;
      });
    };
  
    useEffect(() => {
      loadItemsList();
    }, [lazyState]);

    useEffect(() => {
      loadSuppliersList()
    }, [selectedSupplierGroup]);

    useEffect(() => {
      loadSupplierGroupsList();
      loadManufacturersList();
    }, []);

    const loadItemsList = () => {
      setIsLoading(true);
      let body = JSON.parse(JSON.stringify(lazyState));
      itemMappingsList(body, token, 
        (res) => {
          setTotalRecords(res?.data?.TotalRecords);
          setItemList(res?.data?.Items);
          setIsLoading(false);
        }, 
        async (e) => {       
          if (e.response?.status == 401) {
            showErrorToastNullable(toast, "Session Timeout! Please Login again");
    
            await sleep(5000);
            deleteStoredData();
            navigate("/");
          }
          showErrorToastNullable(toast, e.response?.data?.Message);
          setIsLoading(false);
        });  
    };
  
    const loadSupplierGroupsList = () => {
      groupSupplier(false, token,(res) => {
        setSupplierGroupList(res?.data);
      },
      (error) => {
        showErrorToastNullable(toast, error?.response?.data?.message);
      });
    };

    const loadSuppliersList =() =>
      {
        if (selectedSupplierGroup) {
          getSuppliersList(selectedSupplierGroup,token,(res) => {
              setSupplierList(res?.data[0]?.suppliers);
            },
            (error) => {
              showErrorToastNullable(toast, error?.response?.data?.message);
              console.log(error);
            }
          );  
        } else {
          setSupplierList([]);
        }
      }
    
    const loadManufacturersList = () => {
      getManufacturersList(false, token,(res) => {
        setManufacturerList(res?.data);
      },
      (error) => {
        showErrorToastNullable(toast, error?.response?.data?.message);
      });
    };

    const onGlobalFilterEnter = (e) => {
      const value = e.target.value;
      if (e.key === "Enter") {
        setlazyState(prev => {
          let state = JSON.parse(JSON.stringify(prev));
          state.filters.find(f => f.type === "global").value = [value];
          state.first = 0;
          state.page = 0;
          return state;
        });
      }
    };
    const onGlobalFilterBlur = (e) => {
      const value = e.target.value;
      const prevGlobalFilterValue = lazyState.filters.find(f => f.type === "global").value[0];
      if (value === "" && !!prevGlobalFilterValue && value !== prevGlobalFilterValue) {
        setlazyState(prev => {
          let state = JSON.parse(JSON.stringify(prev));
          state.filters.find(f => f.type === "global").value = [];
          state.first = 0;
          state.page = 0;
          return state;
        });
      }
    };
 
  const fetchAllRecords = async () => {
    try {
      let body = { ...lazyState, rows: 0 };
      await itemMappingsList(body, token, (res) => {
            setIsLoading(true);
            setItemList(res?.data?.Items);  
            setTimeout(() => {
                idt.current.exportCSV(); 
                loadItemsList();
            }, 500);
        }, (error) => {
            console.error("Error fetching all records: ", error);
        });
    } catch (error) {
        console.error("Error in fetchAllRecords: ", error);
    }
};

    const leftToolbarTemplate = () => {
      return(
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
          <h4 className="m-0">ITEM PAGE </h4>
        </div>
      )
    }
    
    const rightToolbarTemplate = () => {
      return (
        <div className="flex flex-wrap gap-2 align-items-center">
        <React.Fragment>
        <label htmlFor="showActive">Is Active:</label>
          <Dropdown 
            value={statusFilterValue}
            options={statusFilterOptions}
            onChange={onStatusFilterChange}
            optionLabel="text"
            optionValue="value"
          />
          
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilterValue}
              onInput={(e) => setGlobalFilterValue(e.target.value)}
              placeholder="Search..."
              onKeyPress={onGlobalFilterEnter}
              onBlur={onGlobalFilterBlur}

            />
          </span>
          <Button
             label="Export"
             icon="pi pi-download"
             className="p-button-help"
             style={{backgroundColor:'#003e7e'}}
             onClick={fetchAllRecords}
          />
          
        </React.Fragment>
        </div>
      );
    };

    const viewItem = (data) => {
      setViewItemDialog(true);
      setItemNumber(data.ItemNumber);
      setSelectedSupplierGroup(data.SupplierGroupId);
      setSelectedSupplier(data.SupplierId);
      setSelectedManufacturer(data.Manufacturer?.ManufacturerId);
      setIsItemActive(data.IsActive);
      setItemId(data.Id);
    };

    const hideDialog = () => {
      setViewItemDialog(false);
    };
    const hideInactivateItemDialog = () => {
      setIsItemActive(true);
      setInactivateItemDialog(false);
    };
    const hideActivateItemDialog = () => {
      setIsItemActive(false);
      setActivateItemDialog(false);
    };
    const confirmActive = () => {
      setActivateItemDialog(false);
    };
    const confirmInactive = () => {
      setInactivateItemDialog(false);
    };
    
    const onSupplierGroupChange = (e) => {
      setSelectedSupplierGroup(e.value);
      setSelectedSupplier(null);
    }

    const onActiveRadioChange = (e) => {
      setIsItemActive(e.value);
      if (e.value === true) {
        setActivateItemDialog(true);
        setInactivateItemDialog(false);
      } else {
        setActivateItemDialog(false);
        setInactivateItemDialog(true);
      }
    };

    const editItem = () => {
      let payload = {
        Id: itemId,
        ItemNumber: itemNumber,
        SupplierGroupId: selectedSupplierGroup,
        SupplierId: selectedSupplier,
        ManufacturerId: selectedManufacturer,
        IsActive: isItemActive,
      }
      saveItemMappings(payload, token, (res) => {
        hideDialog();
        loadItemsList();
        showSuccessToast(toast, "Item edited successfully");
      }, (e) => {
        hideDialog();
        if (e?.response?.status === 401) {
          showErrorToastNullable(toast, "Session timeout! Please logout and login again");
        } 
        else {
          showErrorToastNullable(toast, e?.response?.data?.Message);
        }
      });
    };

    const viewDetailTemplate = (data) => {
      return (
        <Button
          label="View Details"
          style={{ backgroundColor: "#003e7e" }}
          onClick={() => viewItem(data)}
        />
      );
    };

    useEffect(() => {
      setIsItemValid(
        selectedSupplierGroup && 
        selectedSupplier);
    }, [selectedSupplierGroup, selectedSupplier]);

    const editItemDialogFooter = (
      <React.Fragment>
        <Button label="Cancel" style={{backgroundColor:'#003e7e'}} icon="pi pi-times" onClick={hideDialog} />
        <Button label="Save" style={{backgroundColor:'#003e7e'}} icon="pi pi-check" disabled={!isItemValid} onClick={editItem}/>
      </React.Fragment>
    );

    const inactivateItemFooter = (
      <React.Fragment>
      <Button label="Cancel" style={{backgroundColor:'#003e7e'}} icon="pi pi-times"  onClick={hideInactivateItemDialog} />
      <Button label="Confirm" style={{backgroundColor:'#003e7e'}} icon="pi pi-check"  onClick={confirmInactive} /> 
      </React.Fragment>
    )
  
    const activateItemFooter = (
      <React.Fragment>
      <Button label="Cancel" style={{backgroundColor:'#003e7e'}} icon="pi pi-times"  onClick={hideActivateItemDialog} />
      <Button label="Confirm" style={{backgroundColor:'#003e7e'}} icon="pi pi-check"  onClick={confirmActive} /> 
      </React.Fragment>
    )

    const isActiveTemplate = (data) => {
      return data.IsActive === true ? "Yes" : data.IsActive === false ? "No" : "";
    };
  
    const isPFASRelevantTemplate = (data) => {
      return data.IsPFASRelevant === true ? "Yes" : data.IsPFASRelevant === false ? "No" : "";
    };

    const itemNumberExportFormat = (data) => {
      return `\t${data.ItemNumber}`;
    }
    const onPage = (event) => {
      setlazyState(event);
    };
  
    const onSort = (event) => {
      setlazyState(event);
    };
  
    const onFilter = (event) => {
      setlazyState(event);
    }; 
    const formatDate = (dateString) => {
      if (!dateString) return "";
      return moment.utc(dateString).local().format('YYYY-MM-DD HH:mm:ss');
    }; 
      const top80RevenueDateTemplate = (rowData) => {
        return formatDate(rowData.Top80RevenueDate);
    };

    const getStatusText = (status) => {
      if (status === "Internally Reviewed") {
        return "Internally Enriched Validated";
      } else if (status === "ExternallyReviewed") {
        return "Externally Enriched Validated";
      } else if (status == null) {
        return "";
      } else {
        return status;
      }
    };
    const statusBodyTemplate = (rowData) => {
      return getStatusText(rowData.Status?.Status);
    };
    
    return (
       <div>
       <Toast ref={toast} />
       <div className="card">
       <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
       <>
        <DataTable
          ref={idt}
          value={itemList}
          dataKey="Id"
          paginator
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          rows={lazyState.rows}
          lazy
          first={lazyState.first}
          totalRecords={totalRecords}
          onPage={onPage}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          onFilter={onFilter}
          filters={lazyState.filters}
          loading={isLoading}
          scrollable
          scrollHeight="50vh"
          removableSort
        >
          <Column
            body={viewDetailTemplate}
            exportable={false} 
            style={{ minWidth: "11rem" }}
          ></Column>
          <Column field="ItemNumber" header ="Item Number" sortable exportField={itemNumberExportFormat}></Column>
          <Column field="SupplierGroupName" header="Supplier Group" sortable></Column>
          <Column field="SupplierName" header ="Supplier Name" sortable></Column>
          <Column field="Manufacturer.ManufacturerName" header="Manufacturer Name" sortable></Column>
          <Column field="ItemDescription" header ="Item Description" sortable></Column>
          <Column field="VendorPartNumber" header ="Supplier Part Number" sortable></Column>
          <Column field="IsActive" header="Is Active" sortable body={isActiveTemplate} exportField={isActiveTemplate}></Column>
          <Column field="Status.Status" header="Status" body={statusBodyTemplate} exportField={statusBodyTemplate} sortable></Column>
          <Column field="IsPFASRelevant" header ="PFAS Relevancy" sortable body={isPFASRelevantTemplate} exportField={isPFASRelevantTemplate}></Column>
          <Column field="Top80RevenueDate" header ="Top 80 Date" sortable body={top80RevenueDateTemplate} exportField={top80RevenueDateTemplate}></Column>
        </DataTable>
        </>
      </div>
      <Dialog
        visible={viewItemDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Item Details"
        modal
        className="p-fluid"
        footer={editItemDialogFooter}
        onHide={hideDialog}
      >
        <div className="supplierManagementDialog">
          <div>
            <label>
              <b>Item Number</b> : {itemNumber}
            </label>
          </div>
          <div className="supplierManagementField">
            <label htmlFor="SupplierGroup" className="font-bold">
              Supplier Group<span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              id="SupplierGroup"
              value={selectedSupplierGroup}
              options={supplierGroupList}
              onChange={onSupplierGroupChange}
              placeholder="Supplier Group"
              optionLabel="name"
              optionValue="id"
              display="chip"
              filter
              showClear
            />  
          </div>
          <div className="supplierManagementField">
            <label htmlFor="Supplier" className="font-bold">
              Supplier<span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              id="Supplier"
              value={selectedSupplier}
              options={supplierList}
              onChange={(e) => setSelectedSupplier(e.value)}
              placeholder="Supplier"
              optionLabel="name"
              optionValue="id"
              display="chip"
              filter
              showClear
            />  
          </div>
          <div className="supplierManagementField">
            <label htmlFor="Manufacturer" className="font-bold">
              Manufacturer
            </label>
            <Dropdown
              id="Manufacturer"
              value={selectedManufacturer}
              options={manufacturerList}
              onChange={(e) => setSelectedManufacturer(e.value)}
              placeholder="Manufacturer"
              optionLabel="manufacturerName"
              optionValue="manufacturerId"
              display="chip"
              filter
              className="multiSelectWrapChips"
            />  
          </div>
          <div className="flex align-items-center isSupplierActive-container">
            <div>
              <RadioButton 
                name="isItemActive" 
                inputId="itemIsActive" 
                value={true} 
                onChange={onActiveRadioChange}
                checked={isItemActive === true}/>
              <label htmlFor="itemIsActive" className="p-radiobutton-label">Active</label> 
            </div>
            <div>
              <RadioButton 
                name="isItemActive" 
                inputId="itemIsNotActive" 
                value={false} 
                onChange={onActiveRadioChange}
                checked={isItemActive === false}/>
              <label htmlFor="itemIsNotActive" className="p-radiobutton-label">Inactive</label> 
            </div>
          </div>
          <Dialog 
           visible={inactivateItemDialog}
           style={{ width: "25rem" }}
           breakpoints={{ "960px": "75vw", "641px": "90vw" }}
           header="Inactivate Item"
           modal
           className="p-fluid"
           footer={inactivateItemFooter}
           onHide={hideInactivateItemDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              <span>
                Are you sure you want to inactivate the Item?
              </span>
            </div>
          </Dialog>

          <Dialog 
           visible={activateItemDialog}
           style={{ width: "25rem" }}
           breakpoints={{ "960px": "75vw", "641px": "90vw" }}
           header="Activate Item"
           modal
           className="p-fluid"
           footer={activateItemFooter}
           onHide={hideActivateItemDialog}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle mr-3"
                style={{ fontSize: "2rem" }}
              />
              <span>
                Are you sure you want to activate the Item?
              </span>
            </div>
          </Dialog>
        </div>
      </Dialog>
    </div>
    );
}