import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProductService } from "./../services/ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { TabView, TabPanel } from "primereact/tabview";
import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import { ConfirmDialog } from "primereact/confirmdialog"; // To use <ConfirmDialog> tag
import { confirmDialog } from "primereact/confirmdialog"; // To use confirmDialog method
import ImportSupplier from "./ImportSupplier";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css"; // icons
import "primeflex/primeflex.css";
import "./../flag.css";
import { Tooltip } from "primereact/tooltip";
import axios from "axios";
import Storage from "../utils/storage";
import { Link } from "react-router-dom";
import Config from "./../Config";
import "./../App";
import { AS400ColorsDropdown, CertificationsDropdown, ColorsDropdown, MaterialSubTypeLidDropdown, MaterialSubTypesDropdown, compostableDropdown, errorHandleProducts, fetchCertificationsDropdown, hfmCodeDropdown, hfmFinalCodeDropdown, hfmSubCodeDropdown, itemList, materialSubTypeDropdown, materialSubTypeLidDropdown, materialSubTypesDropdown, materialTypeDropdown, materialTypeLidDropdown, materialTypesDropdown, primaryCategoryDropdown, productTypeItemLidDropdown, reusableDropdown, saveProducts, secondaryCategoryDropdown, secondarySubCategoryDropdown, subCategoryDropdown, updateItemHfm, validateProducts, viewProductsDetail, GetItemAudit } from "../services/ApproverService";
import { fetchColorsDropdown } from "../services/ApproverService";
import { fetchAS400ColorsDropdown } from "../services/ApproverService";
import Comments from "./Comments";
import CommentStyles from "../styles/CommentStyles.css";
import { useNavigate } from "react-router-dom";
import { getAccessToken, deleteStoredData } from "../utils/http";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { isApprover, isApproverOrViewer, isESG, isExternalAuditor, isExternalEnricher, isExternalQuality, isInternalEnricher, isInternalQuality, isQuality, isViewer } from "../utils/roleValidator";
import ItemsDataTable from "../components/ItemsDataTable";
import { Status, isStatusApproved, isStatusInReview, isStatusInProgress, isStatusInternallyEnriched, isStatusExternallyEnriched, isStatusExternallyReviewed, isStatusInternallyReviewed, isStatusPending } from "../utils/itemStatusValidator";
import { hfmEnricherCodes, productUseByConsumer,plasticFilmPrePost } from "../constants/ItemFields";
import { showErrorToast, showErrorToastNullable, showSuccessToast, showWarningToast } from "../services/ToastService";
import AuditTrail from "../components/AuditTrail";

export default function ItemsListApprover() {
  const navigate = useNavigate();
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [selectedCustomPrinted, setselectedCustomPrinted] = useState(null);
  const [selectedComment, setSelectedComment] = useState("");
  const [selectedCustomerExclusive, setselectedCustomerExclusives] =
    useState(null);
  const [selectedIncludePFA, setselectedIncludePFA] = useState(null);
  const [selectedIncludePthalates, setselectedIncludePthalates] =
    useState(null);
  const [selectedProductConsumer, setselectedProductConsumer] = useState(null);
  const [selectedPlasticFilmEmbossed, setselectedPlasticFilmEmbossed] =
    useState(null);
  const [selectedCoatedPaper, setselectedCoatedPaper] = useState(null);
  const [selectedProductTypeLid, setselectedProductTypeLid] = useState(null);
  const [selectedProductTypeLidDropdown, setselectedProductTypeLidDropdown] =
    useState(null);
  const [
    selectedPlasticFilmPrePostEmbossed,
    setselectedPlasticFilmPrePostEmbossed,
  ] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryDropdown, setSelectedCountryDropdown] = useState(null);
  const [selectedColors, setSelectedColors] = useState(null);
  const [selectedAS400Colors, setSelectedAS400Colors] = useState(null);
  const [selectedReusable, setSelectedReusable] = useState(null);
  const [selectedReusableDropdown, setSelectedReusableDropdown] =
    useState(null);
  const [selectedColorsDropdown, setSelectedColorsDropdown] = useState(null);
  const [selectedAS400ColorsDropdown, setSelectedAS400ColorsDropdown] =
    useState(null);
  const [selectedCertifications1, setSelectedCertifications1] = useState(null);
  const [selectedCertifications2, setSelectedCertifications2] = useState(null);
  const [selectedCertifications3, setSelectedCertifications3] = useState(null);
  const [selectedCertificationsDropdown, setSelectedCertificationsDropdown] =
    useState(null);
  const [selectedCompostable, setSelectedCompostable] = useState(null);
  const [selectedCompostableDropdown, setSelectedCompostableDropdown] =
    useState(null);
  const [selectedMaterialType, setselectedMaterialType] = useState(null);
  const [selectedMaterialTypeDropdown, setselectedMaterialTypeDropdown] =
    useState(null);
  const [selectedMaterialTypeLid, setselectedMaterialTypeLid] = useState(null);
  const [selectedMaterialTypeLidDropdown, setselectedMaterialTypeLidDropdown] =
    useState(null);
  const [selectedMaterialSubType, setselectedMaterialSubType] = useState(null);
  const [selectedMaterialSubTypeDropdown, setselectedMaterialSubTypeDropdown] =
    useState(null);
  const [selectedMaterialSubTypeLid, setselectedMaterialSubTypeLid] =
    useState(null);
  const [
    selectedMaterialSubTypeLidDropdown,
    setselectedMaterialSubTypeLidDropdown,
  ] = useState(null);
  const [selectedPrimaryCategory, setselectedPrimaryCategory] = useState(null);
  const [selectedPrimaryCategoryDropdown, setselectedPrimaryCategoryDropdown] =
    useState(null);
  const [selectedSecondaryCategory, setselectedSecondaryCategory] =
    useState(null);
  const [
    selectedSecondaryCategoryDropdown,
    setselectedSecondaryCategoryDropdown,
  ] = useState();
  const [selectedSecondarySubCategory, setselectedSecondarySubCategory] =
    useState(null);
  const [
    selectedSecondarySubCategoryDropdown,
    setselectedSecondarySubCategoryDropdown,
  ] = useState(null);
  const [selectedHFMCode, setselectedHFMCode] = useState(null);
  const [selectedHFMCodeDropdown, setselectedHFMCodeDropdown] = useState(null);
  const [selectedHFMSubCode, setselectedHFMSubCode] = useState(null);
  const [selectedHFMSubCodeDropdown, setselectedHFMSubCodeDropdown] =
    useState();
  const [selectedHFMFinalCode, setselectedHFMFinalCode] = useState(null);
  const [selectedHFMFinalCodeDropdown, setselectedHFMFinalCodeDropdown] =
    useState(null);
  const [HFMCode, setHFMCode] = useState(null);
  const [HFMSubCode, setHFMSubCode] = useState(null);
  const [TempHFMCode, setTempHFMCode] = useState(null);
  const [TempHFMSubCode, setTempHFMSubCode] = useState(null);
  const [hfmLogic, setHfmLogic] =  useState(null);
  const [HFMFinalCode, setHFMFinalCode] = useState(null);
  const [selectedSubCategory, setselectedSubCategory] = useState(null);
  const [selectedSubCategoryDropdown, setselectedSubCategoryDropdown] =
    useState(null);
  const [products, setProducts] = useState(null);
  const [rowdata, setRowData] = useState(null);
  const [tabDialog, setTabDialog] = useState(null);
  const [errorDialog, setErrorDialog] = useState(null);
  const [rejectedComments, setRejectedComment] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [importDialog, setImportDialog] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState({});
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectPdfFile, setSelectPdfFile] = useState(null);
  const [pdfFileError, setPdfFileError] = useState("");
  const [viewPdf, setViewPdf] = useState(null);
  const [file, setFile] = useState();
  const [error, setError] = useState("");
  const [array, setArray] = useState([]);
  const [isdisabled, setIsDisabled] = useState(false);
  const [isvalid, setIsValid] = useState(false);
  const [isvalidPlasticFilmThinkness, setIsValidPlasticFilmThinkness] =
    useState(false);
  const [isvalidNetProductWeight, setIsValidNetProductWeight] = useState(false);
  const [isvalidGrossProductWeight, setIsValidGrossProductWeight] =
    useState(false);
  const [isvalidSupplierPartNumber, setIsValidSupplierPartNumber] =
    useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [FDCId, setFDCId] = useState(null);
  const [BeverageCupId, setBeverageCupId] = useState(null);
  const [PlasticFilmId, setPlasticFilm] = useState(null);
  const [LidId, setLid] = useState(null);
  const [LidContainerId, setLidContainer] = useState(null);
  const [LidCupId, setLidCup] = useState(null);
  const [PaperId, setPaper] = useState(null);
  const [PaperboardId, setPaperboard] = useState(null);
  const [Paperboard_BagasseId, setPaperboard_Bagasse] = useState(null);
  const [Paperboard_Molded_PulpId, setPaperboard_Molded_Pulp] = useState(null);
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [showValidateDialog, setShowValidateDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuditLoading, setIsAuditLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [searchItemNumberValue, setSearchItemNumberValue] = useState("");
  const [isFoundOnline, setIsFoundOnline] = useState(true);
  const [selectedHFMInternalCode, setselectedHFMInternalCode] = useState(null);
  const [selectedHFMExternalCode, setselectedHFMExternalCode] = useState(null);
  const [selectedHFMLogic, setSelectedHFMLogic] = useState(null);
  const [notes, setNotes] = useState("");
  const [addNotes, setAddNotes] = useState("");
  const [isEditing, setIsEditing] = useState(true);
  const [comments, setComments] = useState("");
  const [auditData, setAuditData] = useState([]);
  const Role = Storage.get("Role");
  const [statusFilterValue, setStatusFilterValue] = useState(isESG(Role) ? [Status.ERRORHANDLING_INREVIEW, Status.IN_REVIEW]: "");
  const [foundOnlineFilterValue, setFoundOnlineFilterValue] = useState("");
  const [top80FilterValue, setTop80FilterValue] = useState(2);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    firstPage: true,
    before: null,
    after: null,
    lastPage: false,
    filters: isExternalAuditor(Role) ? [
      {
        type: "column",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: "ItemNumber"
      }
    ] : isESG(Role) ? [
      {
        type: "global",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: null
      },
      {
        type: "column",
        value: [Status.ERRORHANDLING_INREVIEW, Status.IN_REVIEW],
        matchMode: FilterMatchMode.CONTAINS,
        field: "Status.Status"
      },
      {
        type: "column",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: "IsFoundOnline"
      },
      {
        type: "column",
        value: [null],
        matchMode: FilterMatchMode.NOT_EQUALS,
        field: "Top80RevenueDate"
      }
    ] : isInternalQuality(Role) ? [      
      {
      type: "global",
      value: [],
      matchMode: FilterMatchMode.CONTAINS,
      field: null
    },
    {
      type: "column",
      value: [null],
      matchMode: FilterMatchMode.NOT_EQUALS,
      field: "Top80RevenueDate"
    }
    ] : [
      {
        type: "global",
        value: [],
        matchMode: FilterMatchMode.CONTAINS,
        field: null
      }
    ]
});

  const [websiteAddress, setWebsiteAddress] = useState('');
  const [count, setCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [websiteObserver, setWebsiteObserver] = useState(null);
  const [notesObserver, setNotesObserver] = useState(null);
  const [commentsObserver, setCommentsObserver] = useState(null);
  const token = getAccessToken();
  const userId = Storage.get("user");
  const supplierId = Storage.get("selectedSuppliers");
  const fileReader = new FileReader();
  const allowedExtensions = ["csv"];
  const toast = useRef(null);
  const toastBC = useRef(null);
  const dt = useRef(null);
  const auditDataTable = useRef(null);
  const [sizeOptions] = useState([
    { label: "Accessories", value: "selectedProducts.Category" },
    { label: "Fitness", value: "selectedProducts.Category" },
    { label: "Clothing", value: "selectedProducts.Category" },
  ]);
  const [size, setSize] = useState(sizeOptions[1].value);
  const [item, setItem] = useState(true);

  const formatUrl = (websiteAddress) => {
    return websiteAddress?.startsWith('http://') || websiteAddress?.startsWith('https://')
      ? websiteAddress
      : `http://${websiteAddress}`;
  };
  
  const handleDoubleClick = () => {
    if (websiteAddress) {
      const url = formatUrl(websiteAddress);
      window.open(url, '_blank');
    }
  };

  const onItemRadio =(e) =>
    {
       setItem(e.value);
       
    };

  const approveItem = () => {
    setShowApproveDialog(true);
  };

  const validate = () =>
    {
      setShowValidateDialog(true);
    }

    const errorHandle = () =>
      {
        setShowErrorDialog(true);
      }

  const finalClassification =  selectedHFMFinalCode|| selectedHFMInternalCode || selectedHFMExternalCode  || selectedHFMLogic || 'N/A';  

      const confirmErrorHandleItem = () =>{
        let emptyProduct = {
          Id: product.Id,
          Name: product.Name,
          ItemNumber: product.ItemNumber,
          ItemDescription: product.ItemDescription,
          NetProductWeight: product.NetProductWeight,
          MaterialTypeId: selectedMaterialType,
          MaterialSubTypeId: selectedMaterialSubType,
          MaterialTypeLidId: selectedMaterialTypeLid,
          MaterialSubTypelidId: selectedMaterialSubTypeLid,
          PrimaryCategoryId: selectedPrimaryCategory,
          SecondaryCategoryId: selectedSecondaryCategory,
          SecondarySubCategoryId: selectedSecondarySubCategory,
          SubCategoryId: selectedSubCategory,
          BrandName: product.BrandName,
          SubBrandName: product.SubBrandName,
          CustomPrinted: selectedCustomPrinted,
          CustomerExclusive: selectedCustomerExclusive,
          Customer: product.Customer,
          CountryId: selectedCountry,
          VendorPartNumber: product.VendorPartNumber,
          GTIN: product.GTIN,
          CompostableId: selectedCompostable,
          Renewable: product.Renewable,
          ReusableAndRefillableId: selectedReusable,
          PostConsumerRecyleContent: product.PostConsumerRecyleContent,
          PreConsumerRecyleContent: product.PreConsumerRecyleContent,
          IncludesPFAS: selectedIncludePFA,
          IncludesPthalates: selectedIncludePthalates,
          ColorId: selectedColors,
          AS400ColorId: selectedAS400Colors,
          OutreachVendorNumber: product.OutreachVendorNumber,
          OutreachVendorName: product.OutreachVendorName,
          OutreachVendorContactName: product.OutreachVendorContactName,
          OutreachVendorContactEmail: product.OutreachVendorContactEmail,
          ProductUseByConsumer: selectedProductConsumer,
          ProductType: product.ProductType,
          ProductTypeItemOrLid: selectedProductTypeLid,
          PlasticFilmThinkness: product.PlasticFilmThinkness,
          PlasticFilmEmbossed: selectedPlasticFilmEmbossed,
          CoatedPaper: selectedCoatedPaper,
          PlasticFilmThinknessPrePostEmbossing: selectedPlasticFilmPrePostEmbossed,
          UnitOfMeasureId: product.UnitOfMeasureId,
          BuyPackDescription: product.BuyPackDescription,
          GrossProductWeight: product.GrossProductWeight,
          PrimaryCategory: product.PrimaryCategory,
          SecondaryCategory: product.SecondaryCategory,
          SubCategory: product.SubCategory,
          BuyUnitOfMeasure: product.BuyUnitOfMeasure,
          PackDescription: product.PackDescription,
          SupplierId: product.SupplierId,
          StatusId: product.StatusId,
          Status: product.Status,
          MaterialType: selectedMaterialType,
          UserId: product.UserId,
          CreatedBy: product.CreatedBy,
          ItemEnrichmentDetails: {
            ItemId: product.Id,
            IsFoundOnline: isFoundOnline,
            Website: websiteAddress,
            Notes: notes,
          },
            TempHfmCode : {
            HFM47Code: selectedHFMExternalCode || selectedHFMInternalCode,
            HFM47SubCode:"",
            HFM47Notes:"",
            ItemId:product.Id,
            IsActive: true,
        },
          HfmInternalCode: selectedHFMInternalCode,
          HfmExternalCode: selectedHFMExternalCode,
          //HfmLogic: selectedHFMLogic,
          HfmCode: selectedHFMCode?.HfmCode,
          HfmSubCode: selectedHFMSubCode?.hfmCode,
          HfmFinalCode: selectedHFMSubCode?.finalCode,
          SendToESG : true,
          Comment : comments
        };
        setSubmitted(true);
         errorHandleProducts(emptyProduct,token,(res) => {
            const resdata = res;
            if (resdata.status === 200) {
              showSuccessToast(toast, resdata?.statusText);
              getItemList();
              setProductDialog(false);
              setErrorDialog(false);
            } else if (resdata.status === 401) {
              showWarningToast(toast, resdata?.statusText);
            } else {
              showErrorToast(toast, resdata?.statusText);
            }
          },
          (e) => {
            setErrorDialog(true);
            if (e?.response?.status === 422) {
              if (errorData !== null || errorData !== "") {
                setErrorData(e?.response?.data);
                setErrorDialog(true);
                showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
              }
            } else {
              setErrorDialog(false);
      
              showErrorToast(toast, e.response?.data?.Message, 6000);
            }
          });  
          onHideErrorDailog();
      }
      const confirmValidateItem = () => {
        let emptyProduct = {
          Id: product.Id,
          Name: product.Name,
          ItemNumber: product.ItemNumber,
          ItemDescription: product.ItemDescription,
          NetProductWeight: product.NetProductWeight,
          MaterialTypeId: selectedMaterialType,
          MaterialSubTypeId: selectedMaterialSubType,
          MaterialTypeLidId: selectedMaterialTypeLid,
          MaterialSubTypelidId: selectedMaterialSubTypeLid,
          PrimaryCategoryId: selectedPrimaryCategory,
          SecondaryCategoryId: selectedSecondaryCategory,
          SecondarySubCategoryId: selectedSecondarySubCategory,
          SubCategoryId: selectedSubCategory,
          BrandName: product.BrandName,
          SubBrandName: product.SubBrandName,
          CustomPrinted: selectedCustomPrinted,
          CustomerExclusive: selectedCustomerExclusive,
          Customer: product.Customer,
          CountryId: selectedCountry,
          VendorPartNumber: product.VendorPartNumber,
          GTIN: product.GTIN,
          CompostableId: selectedCompostable,
          Renewable: product.Renewable,
          ReusableAndRefillableId: selectedReusable,
          PostConsumerRecyleContent: product.PostConsumerRecyleContent,
          PreConsumerRecyleContent: product.PreConsumerRecyleContent,
          IncludesPFAS: selectedIncludePFA,
          IncludesPthalates: selectedIncludePthalates,
          ColorId: selectedColors,
          AS400ColorId: selectedAS400Colors,
          OutreachVendorNumber: product.OutreachVendorNumber,
          OutreachVendorName: product.OutreachVendorName,
          OutreachVendorContactName: product.OutreachVendorContactName,
          OutreachVendorContactEmail: product.OutreachVendorContactEmail,
          ProductUseByConsumer: selectedProductConsumer,
          ProductType: product.ProductType,
          ProductTypeItemOrLid: selectedProductTypeLid,
          PlasticFilmThinkness: product.PlasticFilmThinkness,
          PlasticFilmEmbossed: selectedPlasticFilmEmbossed,
          CoatedPaper: selectedCoatedPaper,
          PlasticFilmThinknessPrePostEmbossing: selectedPlasticFilmPrePostEmbossed,
          UnitOfMeasureId: product.UnitOfMeasureId,
          BuyPackDescription: product.BuyPackDescription,
          GrossProductWeight: product.GrossProductWeight,
          PrimaryCategory: product.PrimaryCategory,
          SecondaryCategory: product.SecondaryCategory,
          SubCategory: product.SubCategory,
          BuyUnitOfMeasure: product.BuyUnitOfMeasure,
          PackDescription: product.PackDescription,
          SupplierId: product.SupplierId,
          StatusId: product.StatusId,
          Status: product.Status,
          MaterialType: selectedMaterialType,
          UserId: product.UserId,
          CreatedBy: product.CreatedBy,
          ItemEnrichmentDetails: {
            ItemId: product.Id,
            IsFoundOnline: isFoundOnline,
            Website: websiteAddress,
            Notes: notes,
          },
            TempHfmCode : {
            HFM47Code: selectedHFMExternalCode || selectedHFMInternalCode,
            HFM47SubCode:"",
            HFM47Notes:"",
            ItemId:product.Id,
            IsActive: true,
        },
          HfmInternalCode: selectedHFMInternalCode,
          HfmExternalCode: selectedHFMExternalCode,
          //HfmLogic: selectedHFMLogic,
          HfmCode: selectedHFMCode?.HfmCode,
          HfmSubCode: selectedHFMSubCode?.hfmCode,
          HfmFinalCode: selectedHFMSubCode?.finalCode,
          Comment : comments
        };
        setSubmitted(true);
         validateProducts(emptyProduct,token,(res) => {
            const resdata = res;
            if (resdata.status === 200) {
              showSuccessToast(toast, resdata?.statusText);
              getItemList();
              setProductDialog(false);
              setErrorDialog(false);
            } else if (resdata.status === 401) {
              showWarningToast(toast, resdata?.statusText);
            } else {
              showErrorToast(toast, resdata?.statusText);
            }
          },
          (e) => {
            setErrorDialog(true);
            if (e?.response?.status === 422) {
              if (errorData !== null || errorData !== "") {
                setErrorData(e?.response?.data);
                setErrorDialog(true);
                showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
              }
            } else {
              setErrorDialog(false);
      
              showErrorToast(toast, e.response?.data?.Message, 6000);
            }
          });  
          onHideValidateDailog();
          }      

  const confirmApproveItem = () => {
    let emptyProduct = {
      Id: product.Id,
      ItemId: product.Id,
      HfmCode: selectedHFMCode?.HfmCode,
      HfmSubCode: selectedHFMSubCode?.hfmCode,
      HfmFinalCode: selectedHFMSubCode?.finalCode,
      IsSystemGenerated: false,
      IsActive: true,
      CreatedBy: userId,
      CreatedDate: product.CreatedDate,
      ModifiedBy: null,
      ModifiedDate: null,
    };
    setSubmitted(true);
    updateItemHfm(emptyProduct,token,(res) => {
        console.log(res,"see");
        const resdata = res;
        console.log(resdata,"res");
        if (resdata.status === 200) {
          showSuccessToast(toast, resdata?.statusText);
          getItemList();
          setProductDialog(false);
          setErrorDialog(false);
          setselectedHFMCode(null);
          setselectedHFMSubCode(null);
          setselectedHFMFinalCode(null);
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          console.log('Coming inside');
          showErrorToast(toast, resdata?.statusText);
        }
      },
      (e) => {
        setErrorDialog(true);
        if (e?.response?.status === 422) {
          if (errorData !== null || errorData !== "") {
            setErrorData(e?.response?.data);
            setErrorDialog(true);
            showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
          }
        } else {
          setErrorDialog(false);
          showErrorToast(toast, e.response?.data?.Message, 6000);
        }
      });
    onHideApproveDialog();
    setselectedHFMCode(null);
    setselectedHFMSubCode(null);
    setselectedHFMFinalCode(null); 
   };

  const onHideApproveDialog = () => {
    setShowApproveDialog(false);
  };

  const onHideValidateDailog = () =>
    {
      setShowValidateDialog(false);
    }

    const onHideErrorDailog = () =>
      {
        setShowErrorDialog(false);
      }

  //Reject code handling
  const rejectItem = () => {
    setShowRejectDialog(true);
  };

  const onHideRejectDialog = () => {
    setShowRejectDialog(false);
  };
  const confirmRejectItem = () => {
    let emptyProduct = {
      Id: product.Id,

      Comment: selectedComment,

      // StatusId: "5085EE6C-2D4B-4789-A5D2-3B328731F802",

      Status: {
        Status: "In Progress",
      },

      CreatedBy: product.CreatedBy,
    };

    setSubmitted(true);

    axios
      .put(`${Config.apiBaseAddress}/api/item/ReviewItem`, emptyProduct, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, resdata?.statusText);

          getItemList();
          setProductDialog(false);
          setErrorDialog(false);
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      })

      .catch((e) => {
        setErrorDialog(true);

        if (e?.response?.status === 422) {
          if (errorData !== null || errorData !== "") {
            setErrorData(e?.response?.data);
            setErrorDialog(true);
            showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
          }
        } else {
          setErrorDialog(false);

          showErrorToast(toast, e.response?.data?.Message, 6000);
        }
      });

    onHideRejectDialog();
    setSelectedComment("");
  };

  const getCountriesDropdown = () => {
    axios
      .get(`${Config.apiBaseAddress}/api/counties`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => {
        setSelectedCountryDropdown(res?.data);
      })

      .catch((e) => {});
  };

  const getColorsDropdown = () => {
    if (Storage.get("selectedColorsDropdown")) {
      setSelectedColorsDropdown(Storage.get("selectedColorsDropdown"));
    } else {
      ColorsDropdown(token,(res) => {
        setSelectedColorsDropdown(res);
        Storage.set("selectedColorsDropdown", res);
      },
      (e) => {});
    }
  };

  const getAS400ColorsDropdown = () => {
    if (Storage.get("selectedAS400ColorsDropdown")) {
      setSelectedAS400ColorsDropdown(Storage.get("selectedAS400ColorsDropdown"));
    } else {
      AS400ColorsDropdown(token,(res) => {
        setSelectedAS400ColorsDropdown(res);
        Storage.set("selectedAS400ColorsDropdown", res);
      },
      (e) => {});
    }
  };

  const getCertificationsDropdown = () => {
    if (Storage.get("selectedCertificationsDropdown")) {
      setSelectedCertificationsDropdown(Storage.get("selectedCertificationsDropdown"));
    } else {
      CertificationsDropdown(token,(res) => {
        setSelectedCertificationsDropdown(res);
        Storage.set("selectedCertificationsDropdown", res);
      },
      (e) => {});
    }
  };

  const getMaterialTypeDropdown = () => {
    if (Storage.get("selectedMaterialTypeDropdown")) {
      setselectedMaterialTypeDropdown(Storage.get("selectedMaterialTypeDropdown"));
    } else {
      materialTypesDropdown(token,(res)=> {
        setselectedMaterialTypeDropdown(res);
        // FindId(res);
        Storage.set("selectedMaterialTypeDropdown", res);
      },
      (e) => {});
    }
  };

  const getMaterialTypeLidDropdown = () => {
    if (Storage.get("selectedMaterialTypeLidDropdown")) {
      setselectedMaterialTypeLidDropdown(Storage.get("selectedMaterialTypeLidDropdown"));
    } else {
      axios
      .get(`${Config.apiBaseAddress}/api/materialTypeLid`, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
     // materialTypeLidDropdown(token)
      .then((res) => {
        setselectedMaterialTypeLidDropdown(res?.data);
        // selectedMaterialTypeDropdown.filter(parentId=>parentId.includes(selectedMaterialType)).map(filteredId=>console.log('filter',filteredId))
        Storage.set("selectedMaterialTypeLidDropdown", res?.data);
      })

      .catch((e) => {});
    }
  };

  const FindId = (data) => {
    var product = data.find((obj) => {
      return obj.name === "Plastic_Film";
    });

    setPlasticFilm(product.id);

    var paper = data.find((obj) => {
      return obj.name === "Paper";
    });

    setPaper(paper.id);

    var paperboard = data.find((obj) => {
      return obj.name === "Paperboard";
    });
    setPaperboard(paperboard.id);

    var paperboard_Bagasse = data.find((obj) => {
      return obj.name === "Paperboard_Bagasse";
    });

    setPaperboard_Bagasse(paperboard_Bagasse.id);

    var paperboard_Molded_Pulp = data.find((obj) => {
      return obj.name === "Paperboard_Molded_Pulp";
    });

    setPaperboard_Molded_Pulp(paperboard_Molded_Pulp.id);
  };

  const getMaterialSubTypeDropdown = () => {
    let materialSubTypes = Storage.get("materialSubTypes");
    if (materialSubTypes[selectedMaterialType]) {
      setselectedMaterialSubTypeDropdown(materialSubTypes[selectedMaterialType]);
    } else {
      materialSubTypeDropdown(selectedMaterialType,token,(res) => {
        setselectedMaterialSubTypeDropdown(res);
        materialSubTypes[selectedMaterialType] = res;
        Storage.set("materialSubTypes", materialSubTypes);
      },
      (e) => {});
    }
  };

  const getMaterialSubTypeLidDropdown = () => {
    axios
      .get(
        `${Config.apiBaseAddress}/api/GetMaterialSubTypesLid/${selectedMaterialTypeLid}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
            "Access-control-allow-origin": "*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      //materialSubTypeLidDropdown(selectedMaterialTypeLid,token)
      .then((res) => {
        setselectedMaterialSubTypeLidDropdown(res?.data);
      })

      .catch((e) => {});
  };

  const getPrimaryCategoryDropdown = () => {
    if (Storage.get("selectedPrimaryCategoryDropdown")) {
      setselectedPrimaryCategoryDropdown(Storage.get("selectedPrimaryCategoryDropdown"));
    } else {
      primaryCategoryDropdown(token,(res)=> {
         setselectedPrimaryCategoryDropdown(res);
         Storage.set("selectedPrimaryCategoryDropdown", res);
       },
       (e) => {});
    }
  };

  useEffect(() => {
    if (selectedMaterialTypeDropdown) {
      FindId(selectedMaterialTypeDropdown);
    }
  }, [selectedMaterialTypeDropdown]);

  useEffect(() => {
    if (selectedMaterialTypeDropdown) {
      FindId(selectedMaterialTypeDropdown);
    }
  }, [selectedMaterialTypeDropdown]);

  const getSecondaryCategoryDropdown = () => {
    let secondaryCategories = Storage.get("secondaryCategories");
    if (secondaryCategories[selectedPrimaryCategory]) {
      setselectedSecondaryCategoryDropdown(secondaryCategories[selectedPrimaryCategory]);
    } else {
      secondaryCategoryDropdown(selectedPrimaryCategory,token,(res) => {
         setselectedSecondaryCategoryDropdown(res);
         secondaryCategories[selectedPrimaryCategory] = res;
         Storage.set("secondaryCategories", secondaryCategories);
       },
       (e) => (e));
    }
  };

  const getHFMCodeDropdown = () => {
    if (Storage.get("selectedHFMCodeDropdown")) {
      setselectedHFMCodeDropdown(Storage.get("selectedHFMCodeDropdown"));
    } else {
      hfmCodeDropdown(token,(res)=> {
        setselectedHFMCodeDropdown(res);
        Storage.set("selectedHFMCodeDropdown", res);
      },
      (e) => {});
    }
  };

  const getHFMSubCodeDropdown = () => {
     hfmSubCodeDropdown(selectedHFMCode,token,(res) => {
        setselectedHFMSubCodeDropdown(res);
      },
      (e) => (e));
  };

  const getHFMFinalCodeDropdown = () => {
      hfmFinalCodeDropdown(selectedHFMSubCode,token,(res)=> {
        setselectedHFMFinalCodeDropdown(res);
      },
      (e) => (e));
  };

  useEffect(() => {
    if (
      selectedSecondaryCategoryDropdown &&
      selectedPrimaryCategory &&
      selectedSecondaryCategoryDropdown
    ) {
      FindSecondaryFDCId(selectedSecondaryCategoryDropdown);
    }
  }, [selectedSecondaryCategoryDropdown, selectedPrimaryCategory]);
  const FindSecondaryFDCId = (data) => {
    var beverage = data.find((obj) => {
      return obj.name === "BEVERAGE CUPS";
    });

    setBeverageCupId(beverage?.id);
    var FDC = data.find((obj) => {
      return obj.name === "FOOD CONTAINERS & LIDS";
    });
    setFDCId(FDC?.id);
  };

  const getSecondarySubCategoryDropdown = () => {
    secondarySubCategoryDropdown(selectedSecondaryCategory,token,(res) => {
        setselectedSecondarySubCategoryDropdown(res);
      },
      (e) => {});
  };

  const getSubCategoryDropdown = () => {
    let subCategories = Storage.get("subCategories");
    if (subCategories[selectedSecondaryCategory]) {
      setselectedSubCategoryDropdown(subCategories[selectedSecondaryCategory]);
    } else {
      subCategoryDropdown(selectedSecondaryCategory,token,(res)=> {
        setselectedSubCategoryDropdown(res);
        subCategories[selectedSecondaryCategory] = res;
        Storage.set("subCategories", subCategories);
      },
      (e) => {});
    }
  };

  const getCompostableDropdown = () => {
    if (Storage.get("selectedCompostableDropdown")) {
      setSelectedCompostableDropdown(Storage.get("selectedCompostableDropdown"));
    } else {
      compostableDropdown(token,(res) => {
        setSelectedCompostableDropdown(res);
        Storage.set("selectedCompostableDropdown", res);
      },
      (e) => {});
    }
  };

  const getReusableDropdown = () => {
    if (Storage.get("selectedReusableDropdown")) {
      setSelectedReusableDropdown(Storage.get("selectedReusableDropdown"));
    } else {
      reusableDropdown(token,(res) => {
        setSelectedReusableDropdown(res);
        Storage.set("selectedReusableDropdown", res);
      },
      (e) => {});
    }
  };

  const getProductTypeItemLidDropdown = () => {
    if (Storage.get("selectedProductTypeLidDropdown")) {
      setselectedProductTypeLidDropdown(Storage.get("selectedProductTypeLidDropdown"));
    } else {
      productTypeItemLidDropdown(token,(res) => {
         setselectedProductTypeLidDropdown(res);
         Storage.set("selectedProductTypeLidDropdown", res);
      },
      (e) => {});
    }
  };

  useEffect(() => {
    if (selectedProductTypeLidDropdown) {
      FindProductTypeLidId(selectedProductTypeLidDropdown);
    }
  }, [selectedProductTypeLidDropdown]);
  const FindProductTypeLidId = (data) => {
    var lid = data.find((obj) => {
      return obj.name === "Lid";
    });

    setLid(lid.id);

    var lidContainer = data.find((obj) => {
      return obj.name === "Container/Lid Combo";
    });

    setLidContainer(lidContainer.id);

    var lidCup = data.find((obj) => {
      return obj.name === "Cup/Lid Combo";
    });
    setLidCup(lidCup.id);
  };

  const compostable = [
    { name: "Certified Not Compostable", code: 1 },
    { name: "Certified Compostable-Industrial", code: 2 },
    { name: "Certified Compostable-Home and Industrial", code: 3 },
  ];
  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

    useEffect(() => {
        getColorsDropdown();
        getAS400ColorsDropdown();
        getCertificationsDropdown();
        getMaterialTypeDropdown();
        getMaterialTypeLidDropdown();
        getCompostableDropdown();
        getReusableDropdown();
        getPrimaryCategoryDropdown();
        getHFMCodeDropdown();
        getProductTypeItemLidDropdown();
    }, []);

    useEffect(() => {
      if (!isFoundOnline) {
        setWebsiteAddress('');
      }
    }, [isFoundOnline]);

  useEffect(() => {
      getItemList();
  }, [userId, lazyState]);

    useEffect(() => {
        if (selectedMaterialType) {
            getMaterialSubTypeDropdown();
        }
    }, [selectedMaterialType]);

    useEffect(() => {
        if (selectedMaterialTypeLid) {
            getMaterialSubTypeLidDropdown();
        }
    }, [selectedMaterialTypeLid]);

    useEffect(() => {
        if (!selectedPrimaryCategory) {
            setselectedSecondaryCategory(null);
        }
        if (selectedPrimaryCategory) {
            getSecondaryCategoryDropdown();
        }
    }, [selectedPrimaryCategory]);

    useEffect(() => {
        if (!selectedHFMCode) {
            setselectedHFMSubCode(null);
        }
        if (selectedHFMCode) {
            getHFMSubCodeDropdown();
        }
    }, [selectedHFMCode]);

    useEffect(() => {
        if (selectedSecondaryCategory) {
            getSecondarySubCategoryDropdown();
        }
    }, [selectedSecondaryCategory]);

    useEffect(() => {
        if (selectedSecondaryCategory) {
            getSubCategoryDropdown();
        }
    }, [selectedSecondaryCategory]);

    useEffect(() => {
        if (selectedHFMSubCode && HFMSubCode) {
            getHFMFinalCodeDropdown();
        }
    }, [selectedHFMSubCode, HFMSubCode]);

  const getItemList = () => {
    setIsLoading(true);
    if (isExternalAuditor(Role) && searchItemNumberValue === "") {
      setProducts([]);
      setIsLoading(false);
      return;
    }
    let body = JSON.parse(JSON.stringify(lazyState));
      itemList(body, token, 
        (res) => {
          setTotalRecords(res?.data?.TotalRecords);
          setProducts(res?.data?.Items);
          setIsLoading(false);
        }, 
       async (e) => {
        if (e.response?.status == 401) {
          showErrorToastNullable(toast, "Session Timeout! Please Login again");

          await sleep(5000);
          deleteStoredData();
          navigate("/");
        }
        
        showErrorToastNullable(toast, e.response?.data?.Message);
        setIsLoading(false);
      });
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const openNew = () => {
    // setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const handleOnChangeFile = (e) => {
    setError("");
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      setFile(inputFile);
    }
  };
  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

  const handleOnSubmitFile = (e) => {
    e.preventDefault();
    if (!file) {
      return setError("Enter a valid file");
    } else {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const fileObj = ["application/pdf"];
  const handleFileChange = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileObj.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          setSelectPdfFile(e.target.result);
          setPdfFileError("");
        };
      } else {
        setSelectPdfFile(null);
        setPdfFileError("Please select valid pdf file");
      }
    } else {
      alert("select pdf file");
    }
  };

  // form submit
  const handleSubmitFile = (e) => {
    e.preventDefault();
    if (selectPdfFile !== null) {
      setViewPdf(selectPdfFile);
      showSuccessToast(toast, "File Uploaded");
    } else {
      setViewPdf(null);
    }
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setImportDialog(false);
    setSelectedComment("");
  };

  const errHideDialog = () => {
    setErrorDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    let emptyProduct = {
      Id: product.Id,
      ItemId: product.ItemNumber,
      HfmCode: selectedHFMCode,
      HfmSubCode: selectedHFMSubCode,
      HfmFinalCode: selectedHFMFinalCode,
      IsSystemGenerated: false,
      IsActive: true,
      CreatedBy: userId,
      CreatedDate: product.CreatedDate,
      ModifiedBy: null,
      ModifiedDate: null,
    };

    setSubmitted(true);

    axios
      .put(`${Config.apiBaseAddress}/api/item/UpdateItemHfm`, emptyProduct, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
     // saveProducts(emptyProduct,token)
      .then((res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, resdata?.statusText);

          getItemList();
          setProductDialog(false);
          setErrorDialog(false);
          setselectedHFMCode(null);
          setselectedHFMSubCode(null);
          setselectedHFMFinalCode(null);
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      })

      .catch((e) => {
        setErrorDialog(true);

        if (e?.response?.status === 422) {
          if (errorData !== null || errorData !== "") {
            setErrorData(e?.response?.data);
            setErrorDialog(true);
            showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
          }
        } else {
          setErrorDialog(false);

          showErrorToast(toast, e.response?.data?.Message, 6000);
        }
      });
  };

  const saveProductUpdate = () => {
    let emptyProduct = {
      Id: product.Id,
      Name: product.Name,
      ItemNumber: product.ItemNumber,
      ItemDescription: product.ItemDescription,
      NetProductWeight: product.NetProductWeight,
      MaterialTypeId: selectedMaterialType,
      MaterialSubTypeId: selectedMaterialSubType,
      MaterialTypeLidId: selectedMaterialTypeLid,
      MaterialSubTypelidId: selectedMaterialSubTypeLid,
      PrimaryCategoryId: selectedPrimaryCategory,
      SecondaryCategoryId: selectedSecondaryCategory,
      SecondarySubCategoryId: selectedSecondarySubCategory,
      SubCategoryId: selectedSubCategory,
      BrandName: product.BrandName,
      SubBrandName: product.SubBrandName,
      CustomPrinted: selectedCustomPrinted,
      CustomerExclusive: selectedCustomerExclusive,
      Customer: product.Customer,
      CountryId: selectedCountry,
      VendorPartNumber: product.VendorPartNumber,
      GTIN: product.GTIN,
      CompostableId: selectedCompostable,
      Renewable: product.Renewable,
      ReusableAndRefillableId: selectedReusable,
      PostConsumerRecyleContent: product.PostConsumerRecyleContent,
      PreConsumerRecyleContent: product.PreConsumerRecyleContent,
      IncludesPFAS: selectedIncludePFA,
      IncludesPthalates: selectedIncludePthalates,
      ColorId: selectedColors,
      AS400ColorId: selectedAS400Colors,
      OutreachVendorNumber: product.OutreachVendorNumber,
      OutreachVendorName: product.OutreachVendorName,
      OutreachVendorContactName: product.OutreachVendorContactName,
      OutreachVendorContactEmail: product.OutreachVendorContactEmail,
      ProductUseByConsumer: selectedProductConsumer,
      ProductType: product.ProductType,
      ProductTypeItemOrLid: selectedProductTypeLid,
      PlasticFilmThinkness: product.PlasticFilmThinkness,
      PlasticFilmEmbossed: selectedPlasticFilmEmbossed,
      CoatedPaper: selectedCoatedPaper,
      PlasticFilmThinknessPrePostEmbossing: selectedPlasticFilmPrePostEmbossed,
      UnitOfMeasureId: product.UnitOfMeasureId,
      BuyPackDescription: product.BuyPackDescription,
      GrossProductWeight: product.GrossProductWeight,
      PrimaryCategory: product.PrimaryCategory,
      SecondaryCategory: product.SecondaryCategory,
      SubCategory: product.SubCategory,
      BuyUnitOfMeasure: product.BuyUnitOfMeasure,
      PackDescription: product.PackDescription,
      SupplierId: product.SupplierId,
      StatusId: product.StatusId,
      Status: product.Status,
      MaterialType: selectedMaterialType,
      UserId: product.UserId,
      CreatedBy: userId,
      ItemEnrichmentDetails: {
        ItemId: product.Id,
        IsFoundOnline: isFoundOnline,
        Website: websiteAddress,
        Notes: notes,
      },
      
        TempHfmCode : {
        HFM47Code: selectedHFMExternalCode || selectedHFMInternalCode,
        HFM47SubCode:"",
        HFM47Notes:"",
        ItemId:product.Id,
        IsActive: true,
    },
      HfmInternalCode: selectedHFMInternalCode,
      HfmExternalCode: selectedHFMExternalCode,
      HfmLogic: selectedHFMLogic,
      HFMFinal: selectedHFMFinalCode,
      Comment : comments
    };

    setSubmitted(true);

    axios
      .put(`${Config.apiBaseAddress}/api/item/update`, emptyProduct, {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": `${Config.SubscriptionKey}`,
          "Access-control-allow-origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const resdata = res;
        if (resdata.status === 200) {
          showSuccessToast(toast, "Saved Successfully");

          getItemList();
        } else if (resdata.status === 401) {
          showWarningToast(toast, resdata?.statusText);
        } else {
          showErrorToast(toast, resdata?.statusText);
        }
      })

      .catch((e) => {
        setErrorDialog(true);

        if (e?.response?.status === 422) {
          if (errorData !== null || errorData !== "") {
            setErrorData(e?.response?.data);
            setErrorDialog(true);
            showErrorToast(toast, "Please Check Error Message Tab in the Form.", 6000);
          }
        } else {
          setErrorDialog(false);

          showErrorToast(toast, e.response?.data?.Message, 6000);
        }
      });
  };
  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
  };
    


  const viewDetail = async (itemId) => {
    setProduct({});
    setCount(0);
    viewProductsDetail(itemId,token,(res)=> {
      setProduct(res?.data);
      setCount(count + 1);
    },
    (e) => {
      showErrorToast(toast, e.response?.statusText);
    });
    if (isExternalAuditor(Role) || isESG(Role) || isViewer(Role)){
      setIsAuditLoading(true);
      GetItemAudit(itemId, token, (res) => {
        setAuditData(res);
        setIsAuditLoading(false);
      }, (e) => {
        showErrorToast(toast, e.response?.statusText);
        setIsAuditLoading(false);
      });
    }
    setProductDialog(true);
  };

  const trackHeights = () => {
    let websiteBox = document.getElementById("WebsiteAddress");
    let notesBox = document.getElementById("Notes");
    let commentsBox = document.getElementById("AddComments");
    const config = { attributes: true, attributeFilter: ["style"] };
    const matchHeightsToWebsite = () => {
      if (websiteBox.style.height != "") {
        requestAnimationFrame(() => {
          if (notesBox.style.height != "" ) {
            notesBox.style.height = "";
          }
          if (commentsBox.style.height != "") {
            commentsBox.style.height = "";
          }
        });
      }
    };
    const matchHeightsToNotes = () => {
      if (notesBox.style.height != "") {
        requestAnimationFrame(() => {
          if (websiteBox.style.height != "") {
            websiteBox.style.height = "";
          }
          if (commentsBox.style.height != "") {
            commentsBox.style.height = "";
          }
        });
      }
    };
    const matchHeightsToComments = () => {
      if (commentsBox.style.height != "") {
        requestAnimationFrame(() => {
          if (websiteBox.style.height != "") {
            websiteBox.style.height = "";
          }
          if (notesBox.style.height != "") {
            notesBox.style.height = "";
          }
        });
      }
    };
    
    const innerWebsiteObserver = new MutationObserver(matchHeightsToWebsite);
    setWebsiteObserver(innerWebsiteObserver);
    innerWebsiteObserver.observe(websiteBox, config);
    const innerNotesObserver = new MutationObserver(matchHeightsToNotes);
    setNotesObserver(innerNotesObserver);
    innerNotesObserver.observe(notesBox, config);
    const innerCommentsObserver = new MutationObserver(matchHeightsToComments);
    setCommentsObserver(innerCommentsObserver);
    innerCommentsObserver.observe(commentsBox, config);
  };

  useEffect(() => {
    if (product) {
      let product_data = product?.MaterialTypeId;
      setselectedMaterialType(product_data);
      setselectedMaterialSubType(product?.MaterialSubTypeId);
      setselectedMaterialTypeLid(product?.MaterialTypeLidId);
      setselectedMaterialSubTypeLid(product?.MaterialSubTypeLidId);
      setselectedPrimaryCategory(product?.PrimaryCategoryId);
      setselectedSecondaryCategory(product?.SecondaryCategoryId);
      setselectedSecondarySubCategory(product?.SecondarySubCategoryId);
      setselectedSubCategory(product?.SubCategoryId);
      setselectedCoatedPaper(product?.CoatedPaper);
      setselectedCustomPrinted(product?.CustomPrinted);
      setselectedCustomerExclusives(product?.CustomerExclusive);
      setselectedIncludePFA(product?.IncludesPFAS);
      setselectedIncludePthalates(product?.IncludesPthalates);
      setselectedPlasticFilmEmbossed(product?.PlasticFilmEmbossed);
      setselectedPlasticFilmPrePostEmbossed(
        product?.PlasticFilmThinknessPrePostEmbossing
      );
      setselectedProductConsumer(product?.ProductUseByConsumer);
      setselectedProductTypeLid(product?.ProductTypeItemOrLid);
      setSelectedCompostable(product?.CompostableId);
      setSelectedColors(product?.ColorId);
      setSelectedAS400Colors(product?.AS400ColorId);
      setSelectedCountry(product?.CountryId);
      setSelectedReusable(product?.ReusableAndRefillableId);
      setHFMCode(product?.HFMLogic ? product?.HFMLogic?.HfmCode : product?.ItemHfmMapping?.HfmCode);
      setHFMSubCode(product?.HFMLogic ? product?.HFMLogic?.HfmSubCode : product?.ItemHfmMapping?.HfmSubCode);
      setHFMFinalCode(product?.HfmFinalCode?.HfmFinalCode);
      setselectedHFMCode(product?.ItemHfmMapping?.HfmCode);
      setselectedHFMSubCode(product?.ItemHfmMapping?.HfmSubCode);
      setTempHFMCode(product?.TempHfmCode?.HFM47Code);
      setTempHFMSubCode(product?.TempHfmCode?.HFM47SubCode);
      setselectedHFMFinalCode(product?.ItemHfmMapping?.HfmFinalCode)
      setIsFoundOnline(product?.ItemEnrichmentDetails?.IsFoundOnline);
      setWebsiteAddress(product?.ItemEnrichmentDetails?.Website);
      setselectedHFMInternalCode(product?.HfmInternalCode);
      setselectedHFMExternalCode(product?.HfmExternalCode);
      setSelectedHFMLogic(product?.HFMLogic?.HfmFinalCode);
      setNotes(product?.ItemEnrichmentDetails?.Notes);
      setComments(product?.ItemReviewHist?.Comment);
      setHfmLogic(product?.HFMLogic?.HfmFinalCode);
    }
    
  }, [count]);

  useEffect(() => {
    if (count > 0) {
      if (activeIndex === 0) {
        setTimeout(trackHeights, 500);
      }
      else if (activeIndex === 1) {
        websiteObserver.disconnect();
        notesObserver.disconnect();
        commentsObserver.disconnect();
      }
    }
  }, [activeIndex, count]);

  console.log('hfmCode',HFMCode)

  useEffect(() => {
    if (product && Array.isArray(product.ItemReviewHistory)) {
      const sortedData = product.ItemReviewHistory.sort((a, b) => new Date(b.Date) - new Date(a.Date));
      const latestEntry = sortedData[0];
      if (latestEntry) {
        setComments(latestEntry.Comment);
      }
    }
  }, [product]);

  useEffect(() => {
    if (!isFoundOnline) {
      setWebsiteAddress('');
    }
  }, [isFoundOnline]);

  const importFileDialog = () => {
    setImportDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = () => {
    let _products = products.filter((val) => val.id !== product.id);

    setProducts(_products);
    setDeleteProductDialog(false);
    showSuccessToast(toast, "Product Deleted");
  };

  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const importCSV = (e) => {
    const file = e.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result;
      const data = csv.split("\n");

      // Prepare DataTable
      const cols = data[0].replace(/['"]+/g, "").split(",");
      data.shift();

      const importedData = data.map((d) => {
        d = d.split(",");
        const processedData = cols.reduce((obj, c, i) => {
          c =
            c === "Status"
              ? "inventoryStatus"
              : c === "Reviews"
              ? "rating"
              : c.toLowerCase();
          obj[c] = d[i].replace(/['"]+/g, "");
          (c === "price" || c === "rating") && (obj[c] = parseFloat(obj[c]));
          return obj;
        }, {});

        processedData["id"] = createId();
        return processedData;
      });

      const _products = [...products, ...importedData];

      setProducts(_products);
    };

    reader.readAsText(file, "UTF-8");
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const statusFilterOptions = [
    { text: "All", value: [] },
    { text: Status.PENDING, value: [Status.PENDING] },
    { text: Status.IN_PROGRESS, value: [Status.IN_PROGRESS] }
  ];

    //statusFilterOptions.push({ text: Status.IN_REVIEW, value: Status.IN_REVIEW });
    statusFilterOptions.push({ text: Status.APPROVED, value: [Status.APPROVED] });
    statusFilterOptions.push({ text: Status.INTERNALLY_ENRICHED, value: [Status.INTERNALLY_ENRICHED] });
    statusFilterOptions.push({ text: "Internally Enriched Validated", value: [Status.INTERNALLY_REVIEWED] });
    statusFilterOptions.push({ text: Status.EXTERNALLY_ENRICHED, value: [Status.EXTERNALLY_ENRICHED] });
    statusFilterOptions.push({ text: "Externally Enriched Validated", value: [Status.EXTERNALLY_REVIEWED] });
    statusFilterOptions.push({ text: "In Review/ Error Handling", value: [Status.ERRORHANDLING_INREVIEW, Status.IN_REVIEW] });

  const onStatusFilterChange = (e) => {
    setStatusFilterValue(e.value);
    let value = e.value;
    setlazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "Status.Status").value = value;
      return state;
    });
  };

  const foundOnlineFilterOptions = [
    { text: "All", value: ""},
    { text: "Yes", value: true},
    { text: "No", value: false}
  ];

  const onFoundOnlineFilterChange = (e) => {
    setFoundOnlineFilterValue(e.value);
    let value = e.value === "" ? [] : [e.value];
    setlazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "IsFoundOnline").value = value;
      return state;
    });
  };

  const top80FilterOptions = [
    {text: "All", value: 1},
    {text: "Top 80", value: 2},
    {text: "Middle 19", value: 3},
  ];

  const onTop80FilterChange = (e) => {
    setTop80FilterValue(e.value);
    let value;
    let matchMode;
    switch(e.value) {
        case 2:
          value = [null];
          matchMode = FilterMatchMode.NOT_EQUALS;
          break;
        case 3:
          value = [null];
          matchMode = FilterMatchMode.EQUALS;
          break;
        default:
          value = [];
          matchMode = FilterMatchMode.CONTAINS;
          break;
    }
    setlazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "Top80RevenueDate").matchMode = matchMode;
      state.filters.find(f => f.type === "column" && f.field === "Top80RevenueDate").value = value;
      return state;
    });
  };


  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    showSuccessToast(toast, "Products Deleted");
  };

  const onCategoryChange = (e) => {
    let _product = { ...product };

    _product["category"] = e.value;
    setProduct(_product);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };

    _product[`${name}`] = val;
    if (name === "OutreachVendorContactEmail") {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }

    if (name === "PlasticFilmThinkness") {
      if (!/^\d*\.?\d*$/i.test(e.target.value)) {
        setIsValidPlasticFilmThinkness(true);
      } else {
        setIsValidPlasticFilmThinkness(false);
      }
    }

    if (name === "NetProductWeight") {
      if (!/^\d*\.?\d*$/i.test(e.target.value)) {
        setIsValidNetProductWeight(true);
      } else {
        setIsValidNetProductWeight(false);
      }
    }

    if (name === "GrossProductWeight") {
      if (!/^\d*\.?\d*$/i.test(e.target.value)) {
        setIsValidGrossProductWeight(true);
      } else {
        setIsValidGrossProductWeight(false);
      }
    }

    if (name === "VendorPartNumber") {
      if (!/^[a-z0-9]+$/i.test(e.target.value)) {
        setIsValidSupplierPartNumber(true);
      } else {
        setIsValidSupplierPartNumber(false);
      }
    }

    setProduct(_product);
  };
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };

  const importFile = (e) => {
    return (
      <div>
        <ImportSupplier />
      </div>
    );
  };

  const rejectFunc = () => {
    showWarningToast(toast, "You have rejected", 3000, "Rejected");
  };

  const confirm = () => {
    confirmDialog({
      message:
        "Please confirm your changes before submitting for further review. Do you want to submit this form?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => saveProduct(),
      reject: () => rejectFunc(),
      className: "dialogWidth",
    });
  };

  const activityBodyTemplate = (rowData) => {
    return (
      <ProgressBar
        value={Math.round(rowData.PercentageComplete)}
        pt={{
          value: {
            style:
              rowData.PercentageComplete >= 26 &&
              rowData.PercentageComplete < 100
                ? { background: "orange" }
                : rowData.PercentageComplete <= 25
                ? { background: "red" }
                : { background: "green" },
          },
        }}
        showValue={false}
        style={{ height: "8px" }}
      ></ProgressBar>
    );
  };

  const viewDetailTemplate = (data) => {
    return (<Button
      label="View Details"
      style={{ backgroundColor: "#003e7e" }}
      severity="success"
      onClick={() => viewDetail(data.Id)}
      disabled={isStatusApproved(data?.Status) && isApprover(Role)}
    />);
  }


  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="flex flex-wrap gap-2 ">
          {/* <Button
            label="Import"
            icon="pi pi-upload"
            className="button-color "
            style={{ backgroundColor: "#003e7e" }}
            onClick={importFileDialog}
          /> */}

          <Button
            label="Export"
            icon="pi pi-download"
            className="p-button-help"
            style={{ backgroundColor: "#003e7e" }}
            onClick={exportCSV}
          />
        </div>
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`https://primefaces.org/cdn/primereact/images/product/${rowData.image}`}
        alt={rowData.image}
        className="shadow-2 border-round"
        style={{ width: "64px" }}
      />
    );
  };

  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.price);
  };

  const ratingBodyTemplate = (rowData) => {
    return <Rating value={rowData.rating} readOnly cancel={false} />;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.inventoryStatus}
        severity={getSeverity(rowData)}
      ></Tag>
    );
  };

  const actionBodyTemplate = (rowData) => {
    setRowData(rowData);
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          rounded
          outlined
          className="mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const getSeverity = (product) => {
    switch (product.activity) {
      case product.activity === 100:
        return "success";

      case product.activity >= 26:
        return "warning";

      case product.activity <= 25:
        return "danger";

      default:
        return null;
    }
  };

  const onIsFoundOnlineRadio =(e) =>
    {
       setIsFoundOnline(e.value);
    };

  const PrimaryCategoryChange = (e) => {
    setselectedPrimaryCategory(e.value);
    setselectedSecondaryCategory(null);
    setselectedSubCategory(null);
  };

  const SecondaryCategoryChange = (e) => {
    setselectedSecondaryCategory(e.value);
    setselectedSubCategory(null);
    setselectedProductTypeLid(null);
  };

  const HFMCodeChange = (e) => {
    setselectedHFMCode(e.value);
    setselectedHFMSubCode(null);
    setselectedHFMFinalCode(null);
  };

  const HFMSubCodeChange = (e) => {
    setselectedHFMSubCode(e.value);
    setselectedHFMFinalCode(null);
  };

  const ProductTypeItemLidChange = (e) => {
    setselectedProductTypeLid(e.value);
    setselectedMaterialTypeLid(null);
    setselectedMaterialSubTypeLid(null);
  };

  const MaterialTypeChange = (e) => {
    setselectedMaterialType(e.value);
    setselectedMaterialSubType(null);
  };

  const MaterialTypeLidChange = (e) => {
    setselectedMaterialTypeLid(e.value);
    setselectedMaterialSubTypeLid(null);
  };

  const itemSubmittedDate=(rowData) => {
    if (rowData?.SubmittedDate !== null)
    {
      let date=new Date(rowData?.SubmittedDate);
      var localDate= date?.toLocaleString();
    }
    else{
       localDate=rowData?.SubmittedDate
    }
    return(
      <div>
      {(isStatusApproved(rowData?.Status)||isStatusInReview(rowData?.Status)||isStatusInProgress(rowData?.Status))?
      <span>{localDate}</span>
      : ''}
      
      </div>
    )
  }
  const itemSubmittedBy=(rowData) => {
    return(
      <div>
      {(isStatusApproved(rowData?.Status)||isStatusInReview(rowData?.Status)||isStatusInProgress(rowData?.Status))?
      <span>{rowData?.SubmittedReviewBy}</span>
      : ''}
      
      </div>
    )
  }

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
  };

  const onGlobalFilterEnter = (e) => {
    const value = e.target.value;
    if (e.key === "Enter") {
      setlazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "global").value = [value];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  }
  const onGlobalFilterBlur = (e) => {
    const value = e.target.value;
    const prevGlobalFilterValue = lazyState.filters.find(f => f.type === "global").value[0];
    if (value === "" && !!prevGlobalFilterValue && value !== prevGlobalFilterValue) {
      setlazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "global").value = [];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  }

  const onSearchItemNumberChange = (e) => {
    setSearchItemNumberValue(e.target.value);
  };

  const onSearchItemNumberEnter = (e) => {
    const value = e.target.value;
    if (e.key === "Enter") {
      searchItemNumber(value);
    }
  };

  const searchItemNumber = (value) => {
    setlazyState(prev => {
      let state = JSON.parse(JSON.stringify(prev));
      state.filters.find(f => f.type === "column" && f.field === "ItemNumber").value = [value];
      state.first = 0;
      state.page = 0;
      return state;
    });
  }

  const onSearchItemNumberBlur = (e) => {
    const value = e.target.value;
    const prevGlobalFilterValue = lazyState.filters.find(f => f.type === "column" && f.field === "ItemNumber").value[0];
    if (value === "" && !!prevGlobalFilterValue && value !== prevGlobalFilterValue) {
      setlazyState(prev => {
        let state = JSON.parse(JSON.stringify(prev));
        state.filters.find(f => f.type === "column" && f.field === "ItemNumber").value = [];
        state.first = 0;
        state.page = 0;
        return state;
      });
    }
  }


  const statusProgressBodyTemplate = (rowData) => {
    
    let date=new Date(rowData?.itemHfmMapping?.CreatedDate);
    var localDate= date?.toLocaleString();

    return (
      <div>
        <span>{rowData?.Status?.Status}</span>

        {isStatusApproved(rowData?.Status) &&
        rowData?.itemHfmMapping?.IsSystemGenerated === true ? (
          <span>
            {" "}
            <Tooltip target=".custom-target-iconEnvelope" />
            <i
              className="custom-target-iconEnvelope pi pi-info-circle p-text-secondary p-overlay-badge"
              data-pr-tooltip="HFM Code is system generated."
              data-pr-position="right"
              data-pr-at="right+5 top"
              data-pr-my="left center"
              style={{ fontSize: "20px", cursor: "pointer", color: "blue" }}
            >
              {/* <Badge severity="danger"></Badge> */}
            </i>
          </span>
        ) : isStatusApproved(rowData?.Status) &&
        rowData?.itemHfmMapping?.IsSystemGenerated === false ? (
          <span>
            {" "}
            <Tooltip target=".custom-target-iconEnvelope" />
            <i
              className="custom-target-iconEnvelope pi pi-eye p-text-secondary p-overlay-badge"
              data-pr-tooltip={"Approved by" + " " + (rowData?.itemHfmMapping?.CreatedBy) + " " + "on" + " " + (localDate)}
              data-pr-position="right"
              data-pr-at="right+5 top"
              data-pr-my="left center"
              style={{ fontSize: "20px", cursor: "pointer", color: "Green" }}
            >
              {/* <Badge severity="danger"></Badge> */}
            </i>
          </span>
        ) : (
          ""
        ) }
      </div>
    );
  };
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">ITEM PAGE </h4>
        <div className="flex flex-wrap gap-2 align-items-center">
          {(isESG(Role) || isInternalQuality(Role)) && 
          <>
            <label htmlFor="top80Filter">Sales Bucket:</label>
            <Dropdown 
              inputId="top80Filter"
              value={top80FilterValue}
              options={top80FilterOptions}
              onChange={onTop80FilterChange}
              optionLabel="text"
              optionValue="value"
            />
          </>}
          {isESG(Role) && (
          <>
            <label htmlFor="foundOnlineFilter">Item Found Online:</label>
            <Dropdown 
              inputId="foundOnlineFilter"
              value={foundOnlineFilterValue}
              options={foundOnlineFilterOptions}
              onChange={onFoundOnlineFilterChange}
              optionLabel="text"
              optionValue="value"
            />
            <label htmlFor="statusFilter">Status:</label>
            <Dropdown 
              inputId="statusFilter"
              value={statusFilterValue}
              options={statusFilterOptions}
              onChange={onStatusFilterChange}
              optionLabel="text"
              optionValue="value"
            />
          </>)} 
          {/* <SelectButton value={setGlobalFilter("Accessories")} label='ALL' /> */}
          {!isExternalAuditor(Role) ? 
          <span className="p-input-icon-left">
          <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilterValue}
              onInput={onGlobalFilterChange}
              placeholder="Search..."
              onKeyPress={onGlobalFilterEnter}
              onBlur={onGlobalFilterBlur}
            />
          </span> :
            <>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                value={searchItemNumberValue}
                onInput={onSearchItemNumberChange}
                placeholder="Search Item Number..."
                onKeyPress={onSearchItemNumberEnter}
                onBlur={onSearchItemNumberBlur}
              />
            </span>
              {/* <Button 
                label="search"
                style={{ backgroundColor: "#003e7e" }}
                onClick={() => {searchItemNumber(searchItemNumberValue)}}
              /> */}
            </>
          }
      </div>
    </div>
  );

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        style={{ backgroundColor: "#003e7e" }}
        icon="pi pi-times"
        outlined
        onClick={hideDialog}
      />
      {/* <Button
        label="Save"
        style={{ backgroundColor: "#003e7e" }}
        icon="pi pi-check"
        onClick={saveProduct}
      /> */}
    </React.Fragment>
  );
  const tabCardFooter = (
    <React.Fragment>
      {!(isESG(Role) || isExternalAuditor(Role) || isViewer(Role)) &&
      <Button
        style={{ width: "10rem", marginLeft: 5, backgroundColor: "#003e7e" }}
        label="Save"
        icon="pi pi-check"
        onClick={saveProductUpdate}
        disabled={isStatusInProgress(product?.Status)|| isStatusPending(product?.Status) || isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)}
      />
      }
    </React.Fragment>
  );

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  console.log("stat",product?.Status);
  const tabSubmit = (
    <React.Fragment>
      {isApproverOrViewer(Role) && (
      <Button
        label="Approve"
        icon="pi pi-check-circle"
        style={{ backgroundColor: "#6ab045" }}
        onClick={approveItem}
        disabled={
          !isStatusInReview(product?.Status) || isViewer(Role) ||!selectedHFMCode||!selectedHFMSubCode
        }
      />
      )}
      {isESG(Role) && (
      <Button
        label="Approve"
        icon="pi pi-check-circle"
        style={{ backgroundColor: "#6ab045" }}
        onClick={validate}
        disabled={!selectedPrimaryCategory || !selectedSecondaryCategory || !selectedSubCategory 
          ||!selectedMaterialType || !selectedMaterialSubType}
      />
      )}
      {/* {isESG(Role) && (
      <Button
        label="Return To Supplier"
        icon="pi pi-check-circle"
        style={{ backgroundColor: "#6a1b9a" }}
        //onClick={errorHandle}
      />
      )} */}
      {isQuality(Role) && (
      <Button
        label="Error Handling Send To ESG"
        icon="pi pi-check-circle"
        style={{backgroundColor:'#6a1b9a'}}
        onClick={errorHandle}
        disabled = {isStatusInProgress(product?.Status)|| isStatusPending(product?.Status) ||isStatusExternallyReviewed(product?.Status) || 
          isStatusInternallyReviewed(product?.Status)}
      />
      )}
      
      {isQuality(Role) && (
      <Button
        label="Validated"
        icon="pi pi-check-circle"
        style={{backgroundColor:'#6ab045'}}
        onClick={validate}
         disabled ={isStatusInProgress(product?.Status)|| isStatusPending(product?.Status)|| isStatusExternallyReviewed(product?.Status)|| 
          isStatusInternallyReviewed(product?.Status) ||!selectedPrimaryCategory ||!selectedSecondaryCategory
          ||!selectedSubCategory || !selectedMaterialType ||!selectedMaterialSubType ||selectedIncludePFA == null ||!product?.GrossProductWeight || (isFoundOnline === true && !websiteAddress) ||
          (isFoundOnline == null) ||!product?.ProductType || (isInternalQuality(Role) ? !selectedHFMInternalCode : !selectedHFMExternalCode)
          }
      />
      )}
      
      <Dialog
        header="Submit Item"
        visible={showApproveDialog}
        onHide={onHideApproveDialog}
        modal
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              onClick={onHideApproveDialog}
            />
            <Button
              label="Confirm"
              className="p-button-success"
              onClick={confirmApproveItem}
            />
          </div>
        }
      >
        <p>Are you sure you want to proceed?</p>
      </Dialog>
      <Dialog
        header="Validate Item"
        visible={showValidateDialog}
        onHide={onHideValidateDailog}
        modal
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              onClick={onHideValidateDailog}
            />
            <Button
              label="Confirm"
              className="p-button-success"
              onClick={confirmValidateItem}
            />
          </div>
        }
      >
        <p>Are you sure you want to proceed?</p>
      </Dialog>
      <Dialog
        header="Send To ESG"
        visible={showErrorDialog}
        onHide={onHideErrorDailog}
        modal
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              onClick={onHideErrorDailog}
            />
            <Button
              label="Confirm"
              className="p-button-success"
              onClick={confirmErrorHandleItem}
            />
          </div>
        }
      >
        <p>Are you sure you want to proceed?</p>
      </Dialog>

    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    Name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    PrimaryCategory: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SecondaryCategory: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    SubCategory: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ItemNumber: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    ItemDescription: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "MaterialType.Name": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "Status.Status": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    LastEditDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  return (
    <div>
      {isExternalAuditor(Role) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ color: "#003e7e" }}> Welcome to Bunzl External Auditor Portal</h1>
        </div>
      )}
      <Toast ref={toast} />
      {/* <Toast ref={toastBC} position="top-right" /> */}

      <ConfirmDialog />

      {/* <ImportSupplier/>    */}
      <div className="card">

        <ItemsDataTable 
          Role={Role} 
          products={products} 
          header={header}
          dt={dt}
          viewDetailTemplate={viewDetailTemplate}
          activityBodyTemplate={activityBodyTemplate}
          loading={isLoading}
          lazyState={lazyState}
          setlazyState={setlazyState}
          totalRecords={totalRecords}
        />

      </div>

      <Dialog
        visible={productDialog}
        style={{ width: "96rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Item Details"
        modal
        className="p-fluid"
        footer={tabSubmit}
        onHide={hideDialog}
        maximizable
      >
        <div className="columns">
          <div className="column">
            <div>
              <label>
                <b>Item Number</b> : {product && product?.ItemNumber}
              </label>
            </div>
            <div>
              <label>
                <b>Item Description</b> : {product && product?.ItemDescription}
              </label>
            </div>
            <div>
            <label>
              <b>Status</b> : 
              {product?.Status?.Status === "Internally Reviewed" ? "Internally Enriched Validated" : 
              product?.Status?.Status === "ExternallyReviewed" ? "Externally Enriched Validated" : 
              product?.Status?.Status}
            </label>
          </div>
          </div>

          <div className="column">
            <div>
              <label>
                <b>Supplier Part Number</b> :{" "}
                {product && product?.VendorPartNumber}
              </label>
            </div>
            {(isQuality(Role) || isExternalEnricher(Role) || isInternalEnricher(Role) || isESG(Role) || isExternalAuditor(Role)) && (
            <div>
              <label>
                <b>GTIN</b> :{" "}
                {product && product?.GTIN}
              </label>
            </div>
            )}
            <div>
              <label>
                <b>Supplier Name</b> : {product && product?.Supplier?.Name}
              </label>
            </div>
          </div>
          <div className="column">
            <div>
              <label>
                <b>Buy Unit Of Measure</b> :{" "}
                {product && product?.BuyUnitOfMeasure}
              </label>
            </div>
            <div>
              <label>
                <b>Buy Pack Description</b> :{" "}
                {product && product?.BuyPackDescription}
              </label>
            </div>
            {(isESG(Role) && (
            <div>
              {product?.Manufacturers?.length > 0 && (
                <label>
                  <b>Manufacturer Name</b> : {product?.Manufacturers.map(manufacturer => manufacturer.ManufacturerName).join(", ")}
                </label>
              )}
            </div>
            ))}
          </div>
        </div>

        <Card footer={tabCardFooter}>
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header="Primary Attributes">
            
            <div className="detail-row">
              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  Primary Category  <span style={{ color: "red" }}>*</span>
                  <span>
                    <Tooltip
                      target=".custom-target-iconPrimary"
                      style={{ width: "15rem" }}
                    >
                      Please select the primary category that best describes
                      what the product is.
                    </Tooltip>

                    <i
                      className="custom-target-iconPrimary  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedPrimaryCategory}
                  onChange={(e) => PrimaryCategoryChange(e)}
                  options={selectedPrimaryCategoryDropdown}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a Primary Category"
                  showClear
                  required
                  onBlur={() => setIsDisabled(true)}
                  disabled={isQuality(Role)&& (isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)) ||isApproverOrViewer(Role) || isExternalAuditor(Role)}
                  className="hideCaret p-dropdown-trigger"
                />
                {isdisabled &&
                !selectedPrimaryCategory && 
                typeof selectedPrimaryCategory !== "number" ? (
                  <small className="p-error">
                    Primary Category is required.
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  Secondary Category  <span style={{ color: "red" }}>*</span>
                  <span>
                    <Tooltip
                      target=".custom-target-iconSecondary"
                      style={{ width: "15rem" }}
                    >
                      Please select the secondary category that best describes
                      additional attributes about the product.
                    </Tooltip>

                    <i
                      className="custom-target-iconSecondary  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedSecondaryCategory}
                  onChange={(e) => SecondaryCategoryChange(e)}
                  options={selectedSecondaryCategoryDropdown}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a Secondary Category"
                  showClear
                  required
                  onBlur={() => setIsDisabled(true)}
                  disabled ={isQuality(Role)&& (isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)) ||isApproverOrViewer(Role) || isExternalAuditor(Role)}
                  className="hideCaret p-dropdown-trigger"
                />
                {isdisabled &&
                !selectedSecondaryCategory &&
                typeof selectedSecondaryCategory !== "number" ? (
                  <small className="p-error">
                    Secondary Category is required.
                  </small>
                ) : (
                  ""
                )}
              </div>

              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  Sub Category <span style={{ color: "red" }}>*</span>
                  <span>
                    <Tooltip
                      target=".custom-target-iconSub"
                      style={{ width: "15rem" }}
                    >
                      Please select the sub category that best describes
                      additional attributes about the product.{" "}
                    </Tooltip>

                    <i
                      className="custom-target-iconSub  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedSubCategory}
                  onChange={(e) => setselectedSubCategory(e.value)}
                  options={selectedSubCategoryDropdown}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a Sub Category"
                  showClear
                  required
                  onBlur={() => setIsDisabled(true)}
                  disabled={isQuality(Role)&& (isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)) || isApproverOrViewer(Role) || isExternalAuditor(Role)}
                  className="hideCaret p-dropdown-trigger"
                />
                {isdisabled &&
                !selectedSubCategory && 
                typeof selectedSubCategory !== "number" ? (
                  <small className="p-error">Sub Category is required.</small>
                ) : (
                  ""
                )}
              </div>

              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  Material Type  <span style={{ color: "red" }}>*</span>
                  <span>
                    <Tooltip
                      target=".custom-target-iconMT"
                      style={{ width: "15rem" }}
                    >
                      Please select the dominant raw material (by weight) of
                      the main product component. Examples: Select
                      "Paperboard" for a paperboard box with a plastic window;
                      Select "Metal" for an aluminum foil contained in a
                      paperboard box.
                    </Tooltip>

                    <i
                      className="custom-target-iconMT  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedMaterialType}
                  onChange={(e) => MaterialTypeChange(e)}
                  options={selectedMaterialTypeDropdown}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a Material Type"
                  showClear
                  required
                  onBlur={() => setIsDisabled(true)}
                  disabled={isQuality(Role)&& (isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)) || isApproverOrViewer(Role) || isExternalAuditor(Role)}
                  className="hideCaret p-dropdown-trigger"
                />
                {isdisabled &&
                !selectedMaterialType && 
                typeof selectedMaterialType !== "number" ? (
                  <small className="p-error">Material Type is required.</small>
                ) : (
                  ""
                )}
              </div>

              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  Material Sub Type  <span style={{ color: "red" }}>*</span>
                  <span>
                    <Tooltip
                      target=".custom-target-iconMTS"
                      style={{ width: "15rem" }}
                    >
                      Please select the specific substrate of the main product
                      component (by weight).
                    </Tooltip>

                    <i
                      className="custom-target-iconMTS  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedMaterialSubType}
                  onChange={(e) => setselectedMaterialSubType(e.value)}
                  options={selectedMaterialSubTypeDropdown}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a Material Sub Type"
                  showClear
                  required
                  onBlur={() => setIsDisabled(true)}
                  disabled={isQuality(Role)&& (isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)) || isApproverOrViewer(Role) || isExternalAuditor(Role)}
                  className="hideCaret p-dropdown-trigger"
                />
                {isdisabled &&
                !selectedMaterialSubType &&
                typeof selectedMaterialSubType !== "number" ? (
                  <small className="p-error">Material Sub Type is required.</small>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="detail-row">
              {!isExternalAuditor(Role) && (
              selectedSecondaryCategory === FDCId ||
              selectedSecondaryCategory === BeverageCupId) ? (
                <div className="item-detail">
                  <label htmlFor="ProductTypeItemLid" className="font-bold">
                    Product Type Item or Lid
                    <span>
                      <Tooltip
                        target=".custom-target-iconPTL"
                        style={{ width: "15rem" }}
                      >
                        If applicable, please select a secondary product
                        component for this item.
                      </Tooltip>

                      <i
                        className="custom-target-iconPTL  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>
                  <Dropdown
                    value={selectedProductTypeLid}
                    onChange={(e) => ProductTypeItemLidChange(e)}
                    options={selectedProductTypeLidDropdown}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select Product Type Lid"
                    showClear
                    required
                    onBlur={() => setIsDisabled(true)}
                    className="hideCaret p-dropdown-trigger"
                    disabled={isExternalAuditor(Role) || isViewer(Role)}
                  />
                </div>
              ) : 
                ""}
              {!isExternalAuditor(Role) && (
              selectedProductTypeLid === LidId ||
              selectedProductTypeLid === LidCupId ||
              selectedProductTypeLid === LidContainerId) ? (
                <div className="item-detail">
                  <label htmlFor="name" className="font-bold">
                    Material Type (Lid Only){" "}
                    <span>
                      <Tooltip
                        target=".custom-target-iconMTL"
                        style={{ width: "15rem" }}
                      >
                        Please select the dominant raw material (by weight) of
                        the secondary product component.
                      </Tooltip>

                      <i
                        className="custom-target-iconMTL  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>
                  <Dropdown
                    value={selectedMaterialTypeLid}
                    onChange={(e) => MaterialTypeLidChange(e)}
                    options={selectedMaterialTypeLidDropdown}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select a Material Type Lid"
                    showClear
                    required
                    onBlur={() => setIsDisabled(true)}
                    disabled= {isStatusInReview(product?.Status)|| isStatusExternallyReviewed(product?.Status) || isStatusInternallyReviewed(product?.Status) || isExternalAuditor(Role) ||isViewer(Role)}
                    className="hideCaret p-dropdown-trigger"
                  />
                </div>
              ) : 
                ""
              }
              {!isExternalAuditor(Role) && (
              selectedProductTypeLid === LidId ||
              selectedProductTypeLid === LidContainerId ||
              selectedProductTypeLid === LidCupId) ? (
                <div className="item-detail">
                  <label htmlFor="name" className="font-bold">
                    Material Sub Type (Lid Only){" "}
                    <span>
                      <Tooltip
                        target=".custom-target-iconMTLS"
                        style={{ width: "15rem" }}
                      >
                        Please select the specific substrate of the secondary
                        product component (by weight).
                      </Tooltip>

                      <i
                        className="custom-target-iconMTLS  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>
                  <Dropdown
                    value={selectedMaterialSubTypeLid}
                    onChange={(e) => setselectedMaterialSubTypeLid(e.value)}
                    options={selectedMaterialSubTypeLidDropdown}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select a Material Sub Type Lid"
                    showClear
                    required
                    onBlur={() => setIsDisabled(true)}
                    disabled= {isQuality(Role)&& (isStatusExternallyReviewed(product?.Status) || isStatusInternallyReviewed(product?.Status)) || isExternalAuditor(Role) || isViewer(Role)}
                    className="hideCaret p-dropdown-trigger"
                  />
                </div>
              ) : 
                ""
              }
            </div>

            <div className="detail-row">
              {(
              selectedMaterialType === PlasticFilmId ? (
                <div className="item-detail">
                  <label htmlFor="PlasticFilmThinkness" className="font-bold">
                    Plastic Film Thickness(MILS)
                    <span>
                      <Tooltip
                        target=".custom-target-iconPFT"
                        style={{ width: "15rem" }}
                      >
                        Please enter the thickness of the product in MILS.
                      </Tooltip>

                      <i
                        className="custom-target-iconPFT  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>
                  <InputText
                      minFractionDigits={2}
                      id="PlasticFilmThinkness"
                      name="PlasticFilmThinkness"
                      value={product && product?.PlasticFilmThinkness}
                      onChange={(e) =>
                        onInputChange(e, "PlasticFilmThinkness")
                      }
                      required
                      onBlur={() => setIsDisabled(true)}
                      disabled = {isQuality(Role)&& (isStatusExternallyReviewed(product?.Status) || isStatusInternallyReviewed(product?.Status)) || isExternalAuditor(Role) || isViewer(Role)}
                    />
                  {isvalidPlasticFilmThinkness && (
                    <small className="p-error">
                      Enter only Numeric value like (1,1.0,2.00 etc)
                    </small>
                  )}
                </div>
              ) : 
                ""
              )}
              {(
              selectedMaterialType === PlasticFilmId ? (
                <div className="item-detail">
                  <label htmlFor="PlasticFilmEmbossed" className="font-bold">
                    Plastic Film Embossed YN
                    <span>
                      <Tooltip
                        target=".custom-target-iconPFE"
                        style={{ width: "15rem" }}
                      >
                        Is the Plastic Film Embossed?
                      </Tooltip>

                      <i
                        className="custom-target-iconPFE  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>
                  <Dropdown
                    value={selectedPlasticFilmEmbossed}
                    onChange={(e) => setselectedPlasticFilmEmbossed(e.value)}
                    options={productUseByConsumer}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select Plastic Film Embossed"
                    showClear
                    required
                    onBlur={() => setIsDisabled(true)}
                    disabled = {isQuality(Role)&& (isStatusExternallyReviewed(product?.Status) || isStatusInternallyReviewed(product?.Status)) || isExternalAuditor(Role) || isViewer(Role)}
                    className="hideCaret p-dropdown-trigger"
                  />
                </div>
              ) : 
                ""
              )}
               {(
              selectedMaterialType === PlasticFilmId ? (
                <div className="item-detail" style={{ width: "26%"}}>
                  <label 
                    htmlFor="PlasticFilmThicknessPrePost"
                    className="font-bold">
                    Plastic Film Thickness Pre Post Embossing
                    <span>
                      <Tooltip
                        target=".custom-target-iconPFTE"
                        style={{ width: "15rem" }}
                      >
                        Is the entered Plastic Film Thickness measured before
                        or after product embossing?
                      </Tooltip>

                      <i
                        className="custom-target-iconPFTE  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>
                  <Dropdown
                    value={selectedPlasticFilmPrePostEmbossed}
                    onChange={(e) => setselectedPlasticFilmPrePostEmbossed(e.value)}
                    options={plasticFilmPrePost}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select Plastic Film Pre Post Embossed"
                    showClear
                    required
                    onBlur={() => setIsDisabled(true)}
                    disabled = {isQuality(Role)&& (isStatusExternallyReviewed(product?.Status) || isStatusInternallyReviewed(product?.Status)) || isExternalAuditor(Role) || isViewer(Role)}
                    className="hideCaret p-dropdown-trigger"
                  />
                </div>
              ) : 
                ""
              )}
              {(
              selectedMaterialType === PaperId ||
              selectedMaterialType === PaperboardId ||
              selectedMaterialType === Paperboard_BagasseId ||
              selectedMaterialType === Paperboard_Molded_PulpId ? (
                <div className="item-detail" style={{ width: "20%"}}>
                  <label htmlFor="name" className="font-bold">
                    Coated Paper Or Paperboard</label>
                    <span>
                      <Tooltip
                        target=".custom-target-iconCP"
                        style={{ width: "15rem" }}
                      >
                        For fiber products, is this product coated with
                        another material?
                      </Tooltip>

                      <i
                        className="custom-target-iconCP  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                   
                  
                  <Dropdown
                    value={selectedCoatedPaper}
                    onChange={(e) => setselectedCoatedPaper(e.value)}
                    options={productUseByConsumer}
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Select"
                    showClear
                    required
                    onBlur={() => setIsDisabled(true)}
                    disabled ={isQuality(Role)&& (isStatusExternallyReviewed(product?.Status) || isStatusInternallyReviewed(product?.Status)) || isExternalAuditor(Role) || isViewer(Role)}
                    className="hideCaret p-dropdown-trigger"
                  />
                   </span>
                </div>
              ) : 
                ""
              )}
            </div>

            <div className="detail-row">
              {isViewer(Role) ? (
                <>
                  <div className="item-detail">
                    <label htmlFor="name" className="font-bold">
                      Compostable
                      <span>
                        <Tooltip
                          target=".custom-target-iconComp"
                          style={{ width: "15rem" }}
                        >
                          Does this product have a third party certification
                          for compostability?
                        </Tooltip>

                        <i
                          className="custom-target-iconComp  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black", marginLeft: "3px" }}
                        />
                      </span>
                    </label>
                    <Dropdown
                      value={selectedCompostable}
                      onChange={(e) => setSelectedCompostable(e.value)}
                      options={selectedCompostableDropdown}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Compostable"
                      showClear
                      onBlur={() => setIsDisabled(true)}
                      disabled
                      className="hideCaret p-dropdown-trigger"
                    />
                  </div>
                  <div className="item-detail">
                    <label htmlFor="name" className="font-bold">
                      Reusable / Refillable
                      <span>
                        <Tooltip
                          target=".custom-target-iconReuse"
                          style={{ width: "15rem" }}
                        >
                          Is this product part of reuse or refill system?
                        </Tooltip>

                        <i
                          className="custom-target-iconReuse  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black", marginLeft: "3px" }}
                        />
                      </span>
                    </label>
                    <Dropdown
                      value={selectedReusable}
                      onChange={(e) => setSelectedReusable(e.value)}
                      options={selectedReusableDropdown}
                      optionLabel="name"
                      showClear
                      optionValue="id"
                      placeholder="Select a Reusable"
                      className="hideCaret p-dropdown-trigger w-full md:w-33rem"
                      disabled
                    />
                    {/* { (isdisabled && !selectedReusable && (typeof selectedReusable !== "number")) ? <small className="p-error">Reusable is required.</small>:''} */}
                  </div>

                  <div className="item-detail">
                    <label htmlFor="name" className="font-bold">
                      Includes PFAS 
                      <span>
                        <Tooltip
                          target=".custom-target-iconIP"
                          style={{ width: "15rem" }}
                        >
                          Does this product contain intentionally added PFAS?
                        </Tooltip>

                        <i
                          className="custom-target-iconIP  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black", marginLeft: "3px" }}
                        />
                      </span>
                    </label>
                    <Dropdown
                      value={selectedIncludePFA}
                      onChange={(e) => setselectedIncludePFA(e.value)}
                      options={productUseByConsumer}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a PFAs"
                      required
                      showClear
                      onBlur={() => setIsDisabled(true)}
                      disabled 
                      className="hideCaret p-dropdown-trigger"
                    />
                    {isdisabled &&
                    !selectedIncludePFA && !(isStatusInReview(product?.Status)) &&
                    typeof selectedIncludePFA !== "number" ? (
                      <small className="p-error">
                        Include PFAs is required.
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                
                </>
              ) : (
                <>
                
                 
                 {isESG(Role) && (
                  <div className="item-detail">
                    <label htmlFor="name" className="font-bold">
                      Compostable
                      {/* <span style={{ color: "red" }}>*</span> */}
                      <span>
                        <Tooltip
                          target=".custom-target-iconComp"
                          style={{ width: "15rem" }}
                        >
                          Does this product have a third party certification
                          for compostability?
                        </Tooltip>

                        <i
                          className="custom-target-iconComp  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black", marginLeft: "3px" }}
                        />
                      </span>
                    </label>
                    <Dropdown
                      value={selectedCompostable}
                      onChange={(e) => setSelectedCompostable(e.value)}
                      options={selectedCompostableDropdown}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Compostable"
                      showClear
                      // onBlur={() => setIsDisabled(true)}
                    />
                    {/* {isdisabled &&
                  !selectedCompostable &&
                  typeof selectedCompostable !== "number" ? (
                    <small className="p-error">
                      Compostable is required.
                    </small>
                  ) : (
                    ""
                  )} */}
                  </div>
                 )}
                 {isESG(Role)&& (
                  <div className="item-detail">
                    <label htmlFor="name" className="font-bold">
                      Reusable / Refillable
                      <span>
                        <Tooltip
                          target=".custom-target-iconReuse"
                          style={{ width: "15rem" }}
                        >
                          Is this product part of reuse or refill system?
                        </Tooltip>

                        <i
                          className="custom-target-iconReuse  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black", marginLeft: "3px" }}
                        />
                      </span>
                    </label>
                    <Dropdown
                      value={selectedReusable}
                      onChange={(e) => setSelectedReusable(e.value)}
                      options={selectedReusableDropdown}
                      optionLabel="name"
                      showClear
                      optionValue="id"
                      placeholder="Select a Reusable"
                      className="w-full md:w-33rem"
                    />
                    {/* { (isdisabled && !selectedReusable && (typeof selectedReusable !== "number")) ? <small className="p-error">Reusable is required.</small>:''} */}
                  </div>
                 )}
                  {!isExternalAuditor(Role) &&(
                  <div className="item-detail">
                    <label htmlFor="name" className="font-bold">
                      Includes PFAS {!isESG(Role) &&<span style={{ color: "red" }}>*</span>}
                      <span>
                        <Tooltip
                          target=".custom-target-iconIP"
                          style={{ width: "15rem" }}
                        >
                          Does this product contain intentionally added PFAS?
                        </Tooltip>

                        <i
                          className="custom-target-iconIP  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black", marginLeft: "3px" }}
                        />
                      </span>
                    </label>
                    <Dropdown
                      value={selectedIncludePFA}
                      onChange={(e) => setselectedIncludePFA(e.value)}
                      options={productUseByConsumer}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a PFAs"
                      showClear
                      disabled ={isQuality(Role)&& (isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)) || isApproverOrViewer(Role) || isExternalAuditor(Role)}
                       onBlur={() => setIsDisabled(true)}
                    />
                    {isdisabled &&!isESG(Role) &&
                      (!selectedIncludePFA ||
                      typeof selectedIncludePFA !== "number") ? (
                        <small className="p-error">
                          Include PFAs is required.
                        </small>
                      ) : (
                        ""
                      )
                    }
                  </div>
                  )}
                  
                  {isQuality(Role)&& (
                  <div className="item-detail" >
                    <label htmlFor="name" className="font-bold">
                      Item Found Online? <span style={{ color: "red" }}>*</span>
                      <span>
                        <Tooltip target=".custom-target-iconIFO" style={{width:'15rem'}} >
                        If product specifications are available publicly on web, please share link where found.              
                        </Tooltip>

                        <i className="custom-target-iconIFO  pi pi-info-circle"
                        data-pr-position="top"
                        style={{color:'black'}}
                          />
                      </span>
                    </label>
                    <div className="flex align-items-center">
                      <div>
                        <RadioButton name="Yes" value={true} onChange={onIsFoundOnlineRadio}
                        checked={isFoundOnline === true} />
                        <label htmlFor="item1" className="p-radiobutton-label">Yes</label> 
                      </div>
                    <div style={{ marginRight: '10px' }} /> 
                      <div>
                        <RadioButton name="No" value={false} onChange={onIsFoundOnlineRadio}
                        checked={isFoundOnline === false} disabled={isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)}/>
                        <label htmlFor="item2" className="p-radiobutton-label">No</label> 
                      </div>
                    </div>
                  </div>
                  )}

                    {isQuality(Role)&& (
                    <div className="item-detail">
                      <label htmlFor="WebsiteAddress" className="font-bold">
                          Website Address {!isFoundOnline && <span style={{ color: "red" }}>*</span>}
                      </label>
                     
                      <InputTextarea
                        id="WebsiteAddress"
                        className="flex-grow-1"
                        value={websiteAddress || ""}
                        onChange={(e) => setWebsiteAddress(e.target.value)}
                        placeholder="Copy and Paste Url Where Item is Found" 
                        required={isFoundOnline}
                        disabled={!isFoundOnline || isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)}
                      />
                      <a href={websiteAddress} target="_blank" rel="noopener noreferrer"></a>
                      {isdisabled && isFoundOnline &&
                        !websiteAddress && 
                        typeof websiteAddress !== "" ? (
                          <small className="p-error">
                            Website Address is required.
                          </small>
                        ) : (
                          ""
                        )
                      }
                    </div>
                    )}

                  {isQuality(Role) && (
                  <div className="item-detail">
                    <label htmlFor="Notes" className="font-bold">
                      Notes 
                      <span style={{ marginLeft: '5px' }}>
                        <Tooltip
                          target=".custom-target-iconNotes"
                          style={{ width: "15rem" }}
                        >
                          If material type selected is OTHER, please list specific material type here. ​
                          If product is now inactive, please indicate as such
                        </Tooltip>

                          <i
                            className="custom-target-iconNotes pi pi-info-circle"
                            data-pr-position="top"
                            style={{ color: "black" }}
                          />
                        </span>
                      </label>
                      <InputTextarea
                        id="Notes"
                        className="flex-grow-1"
                        placeholder="Open text for comments"
                        value={notes || ""}
                        onChange={(e) => setNotes(e.target.value)}
                        disabled={ isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)}
                      />
                      </div>
                    )}
                  </>
                )}
              {(isESG(Role) || isViewer(Role))&& (
                <div className="item-detail">
                  <label htmlFor="name" className="font-bold">
                    Includes Phthalates
                    <span>
                      <Tooltip
                        target=".custom-target-iconIPH"
                        style={{ width: "15rem" }}
                      >
                        Does this product contain Phthalates?
                      </Tooltip>

                    <i
                      className="custom-target-iconIPH  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedIncludePthalates}
                  onChange={(e) => setselectedIncludePthalates(e.value)}
                  options={productUseByConsumer}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a Phthalates"
                  showClear
                  required
                  onBlur={() => setIsDisabled(true)}
                  className="hideCaret p-dropdown-trigger"
                  disabled={isExternalAuditor(Role) || isViewer(Role)}
                />
              </div>
            )}
            </div>
      

            <div className="detail-row">
              {(isESG(Role) || isViewer(Role)) && (
              <div className="item-detail">
                 <label htmlFor="PostConsumerRecyleContent" className="font-bold" style={{ fontSize: "13px" }}>
                    Post-Consumer Recycled Content
                    <span>
                      <Tooltip
                        target=".custom-target-iconPCRC"
                        style={{ width: "15rem" }}
                      >
                        What is the % of Post-Consumer Recycled Content that the
                    product contains? Post-Consumer and Pre-consumer Recycled
                    Content combined cannot be greater than 1 Please enter a
                    value between 0 - 1 (0.5 = 50%)
                      </Tooltip>

                      <i
                        className="custom-target-iconPCRC  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>

                  <InputText
                    id="PostConsumerRecyleContent"
                    minFractionDigits={2}
                    value={product && product?.PostConsumerRecyleContent}
                    min={0.0}
                    max={1.0}
                    onChange={(e) =>
                      onInputChange(e, "PostConsumerRecyleContent")
                    }
                    disabled={isExternalAuditor(Role) || isViewer(Role)}
                  />
                
                {/* { isdisabled && (product.PostConsumerRecyleContent =='') && (product.PostConsumerRecyleContent == null) && !product.PostConsumerRecyleContent && <small className="p-error">PostConsumerRecyleContent is required.</small>} */}
                {/* { isdisabled && !product.PostConsumerRecyleContent && <small className="p-error">Value should be between 0.00 and 1.00.</small>} */}
                {/* { isvalid && <small className="p-error">PostConsumerRecyleContent value should be between 0 and 1.</small>} */}
              </div>
              )}
              {(isESG(Role) || isViewer(Role)) && (
              <div className="item-detail">
                <label htmlFor="PreConsumerRecyleContent" className="font-bold" style={{ fontSize: "13px" }}>
                    Pre-Consumer Recycled Content
                    <span>
                      <Tooltip
                        target=".custom-target-iconPRCRC"
                        style={{ width: "15rem" }}
                      >
                        What is the % of Pre-Consumer (Post Industrial) Content
                    that the product contains? Please enter a value between 0
                    - 1 (0.5 = 50%)
                      </Tooltip>

                      <i
                        className="custom-target-iconPRCRC  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>

                  <InputText
                    id="PreConsumerRecyleContent"
                    min={0}
                    max={1}
                    value={product && product?.PreConsumerRecyleContent}
                    minFractionDigits={2}
                    onChange={(e) =>
                      onInputChange(e, "PreConsumerRecyleContent")
                    }
                    disabled={isExternalAuditor(Role)|| isViewer(Role)}
                  />
                
                {/* {isdisabled && !product.PreConsumerRecyleContent && <small className="p-error">Value should be between 0.00 and 1.00.</small>} */}
              </div>
              )}
              {(isESG(Role) || isViewer(Role)) && (
              <div className="item-detail">
                <label htmlFor="Renewable" className="font-bold" style={{ fontSize: "14px" }}>
                    Renewable/Bio Based Content
                    <span>
                      <Tooltip
                        target=".custom-target-iconRBC"
                        style={{ width: "15rem" }}
                      >
                        What is the % of Renewable/Bio-Based Content that the
                    product contains? Please enter a value between 0 - 1 (0.5
                    = 50%)
                      </Tooltip>

                      <i
                        className="custom-target-iconRBC  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>
                  <InputText
                    id="Renewable"
                    min={0}
                    max={1}
                    minFractionDigits={2}
                    value={product && product?.Renewable}
                    onChange={(e) => onInputChange(e, "Renewable")}
                    disabled={isExternalAuditor(Role)|| isViewer(Role)}
                  />
                {/* {isdisabled && !product.Renewable && <small className="p-error">Value should be between 0.00 and 1.00.</small>} */}
              </div>
              )}   
              {!isExternalAuditor(Role) &&(
              <div className="item-detail">
              <label htmlFor="GrossProductWeight" className="font-bold" >
                    Gross Product Weight
                    <span>
                      <Tooltip
                        target=".custom-target-iconGPW"
                        style={{ width: "15rem" }}
                      >
                        <ul>
                      <li>
                        Must be gross weight of the product (Including
                        in-transit packaging) in pounds.
                      </li>
                      <li>
                        Gross Product Weight must be greater than Net Product
                        Weight.
                      </li>
                      <li>Value must be numeric.</li>
                    </ul>
                      </Tooltip>

                      <i
                        className="custom-target-iconGPW  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>
                  <InputText
                    id="GrossProductWeight"
                    min={0}
                    max={1}
                    value={product && product?.GrossProductWeight}
                    minFractionDigits={2}
                    onChange={(e) => onInputChange(e, "GrossProductWeight")}
                    onBlur={() => setIsDisabled(true)}
                    disabled={isExternalAuditor(Role) || isViewer(Role)}
                  />
                {/* {isdisabled && !product.GrossProductWeight && (
                  <small className="p-error">
                    Gross Product weight is required.
                  </small>
                )}
                {isvalidGrossProductWeight && (
                  <small className="p-error">
                    Enter only Numeric value like (1,1.0,2.00 etc)
                  </small>
                )} */}
              </div>
              )} 

              {(isESG(Role) || isViewer(Role)) && (
              <div className="item-detail">
                <label htmlFor="NetProductWeight" className="font-bold" >
                    Net Product Weight
                    <span>
                      <Tooltip
                        target=".custom-target-iconNPW"
                        style={{ width: "15rem" }}
                      >
                        <ul>
                      <li>
                        Must be net weight of the product (Excluding
                        in-transit packaging) in pounds.
                      </li>
                      <li>
                        Net Product Weight must be less than Gross Product
                        Weight.
                      </li>
                      <li>Value must be numeric.</li>
                    </ul>
                      </Tooltip>

                      <i
                        className="custom-target-iconNPW  pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black", marginLeft: "3px" }}
                      />
                    </span>
                  </label>

                  <InputText
                    id="NetProductWeight"
                    min={0}
                    max={1}
                    value={product && product?.NetProductWeight}
                    minFractionDigits={2}
                    onChange={(e) => onInputChange(e, "NetProductWeight")}
                    tooltipOptions={{ position: "top" }}
                    disabled={isExternalAuditor(Role) || isViewer(Role)}
                  />
                {/* {isdisabled && !product.NetProductWeight && <small className="p-error">Net Product weight is required.</small>} */}
                {isvalidNetProductWeight && (
                  <small className="p-error">
                    Enter only Numeric value like (1,1.0,2.00 etc)
                  </small>
                )}
              </div>
              )}
            </div>

            <div className="detail-row">
              {(isESG(Role) || isViewer(Role))&& (
              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  3rd Party Certifications_1{" "}
                </label>
                <Dropdown
                  value={selectedCertifications1}
                  onChange={(e) => setSelectedCertifications1(e.value)}
                  options={selectedCertificationsDropdown}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Certification"
                  showClear
                  className="hideCaret p-dropdown-trigger w-full md:w-33rem"
                  disabled={isExternalAuditor(Role) || isViewer(Role)}
                />
              </div>
              )}
               {(isESG(Role) || isViewer(Role)) && (
              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  3rd Party Certifications_2{" "}
                </label>
                <Dropdown
                  value={selectedCertifications2}
                  onChange={(e) => setSelectedCertifications2(e.value)}
                  options={selectedCertificationsDropdown}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Certification"
                  showClear
                  className="hideCaret p-dropdown-trigger w-full md:w-33rem"
                  disabled={isExternalAuditor(Role) || isViewer(Role)}
                />
              </div>
               )}
               {(isESG(Role) || isViewer(Role))&& (
              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  3rd Party Certifications_3{" "}
                </label>
                <Dropdown
                  value={selectedCertifications3}
                  onChange={(e) => setSelectedCertifications3(e.value)}
                  options={selectedCertificationsDropdown}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Certification"
                  showClear
                  className="hideCaret p-dropdown-trigger w-full md:w-33rem"
                  disabled={isExternalAuditor(Role) || isViewer(Role)}
                />
              </div>
               )}

              { isViewer(Role) ? (
                <>
                  <div className="item-detail">
                    <label htmlFor="name" className="font-bold">
                      Sustainable Color
                      <span>
                        <Tooltip
                          target=".custom-target-iconSC"
                          style={{ width: "15rem" }}
                        >
                          Please select the dominant color of the product.
                        </Tooltip>

                        <i
                          className="custom-target-iconSC  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black", marginLeft: "3px" }}
                        />
                      </span>
                    </label>
                    <Dropdown
                      value={selectedColors}
                      onChange={(e) => setSelectedColors(e.value)}
                      options={selectedColorsDropdown}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Sustainable Color"
                      showClear
                      required
                      onBlur={() => setIsDisabled(true)}
                      disabled
                      className="hideCaret p-dropdown-trigger"
                    />
                  </div>
                  {isViewer(Role) && (
              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  AS400 Color
                  {/* <span style={{ color: "red" }}>*</span> */}
                  <span>
                    <Tooltip
                      target=".custom-target-iconAC"
                      style={{ width: "15rem" }}
                    >
                      Please select the dominant color of the product.
                    </Tooltip>

                    <i
                      className="custom-target-iconAC  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedAS400Colors}
                  onChange={(e) => setSelectedAS400Colors(e.value)}
                  options={selectedAS400ColorsDropdown || []}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a AS400 Color"
                  showClear
                  // onBlur={() => setIsDisabled(true)}
                  disabled={isViewer(Role)}
                />
              </div>
              )}
                </>
              ) : (
                <>
                {isESG(Role)&& (
                  <div className="item-detail">
                    <label htmlFor="name" className="font-bold">
                      Sustainable Color
                      {/* <span style={{ color: "red" }}>*</span> */}
                      <span>
                        <Tooltip
                          target=".custom-target-iconSC"
                          style={{ width: "15rem" }}
                        >
                          Please select the dominant color of the product.
                        </Tooltip>

                        <i
                          className="custom-target-iconSC  pi pi-info-circle"
                          data-pr-position="top"
                          style={{ color: "black", marginLeft: "3px" }}
                        />
                      </span>
                    </label>
                    <Dropdown
                      value={selectedColors}
                      onChange={(e) => setSelectedColors(e.value)}
                      options={selectedColorsDropdown || []}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select a Sustainable Color"
                      showClear
                      // onBlur={() => setIsDisabled(true)}
                    />
                    {/* {isdisabled &&
                  !selectedColors &&
                  typeof selectedColors !== "number" ? (
                    <small className="p-error">
                      Sustainable Color is required.
                    </small>
                  ) : (
                    ""
                  )} */}
                  </div>
                )}
                </>
              )}
              {isESG(Role) && (
              <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                  AS400 Color
                  {/* <span style={{ color: "red" }}>*</span> */}
                  <span>
                    <Tooltip
                      target=".custom-target-iconAC"
                      style={{ width: "15rem" }}
                    >
                      Please select the dominant color of the product.
                    </Tooltip>

                    <i
                      className="custom-target-iconAC  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedAS400Colors}
                  onChange={(e) => setSelectedAS400Colors(e.value)}
                  options={selectedAS400ColorsDropdown || []}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select a AS400 Color"
                  showClear
                  // onBlur={() => setIsDisabled(true)}
                  disabled={isExternalAuditor(Role) || isViewer(Role)}
                />
              </div>
              )}
            </div>

            <div className="detail-row">
              {(isESG(Role) || isViewer(Role))&& (
              <div className="item-detail">
                <label htmlFor="ProductUseByConsumer" className="font-bold">
                  Product Use By Consumer
                  <span>
                    <Tooltip
                      target=".custom-target-iconPU"
                      style={{ width: "15rem" }}
                    >
                      For Packaging: Is this item used to package products
                      sold to the consumer? For Food Service Products
                      (Cutlery, Straws, Plates): Can this item be taken off
                      premises?
                    </Tooltip>

                    <i
                      className="custom-target-iconPU  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black", marginLeft: "3px" }}
                    />
                  </span>
                </label>
                <Dropdown
                  value={selectedProductConsumer}
                  onChange={(e) => setselectedProductConsumer(e.value)}
                  options={productUseByConsumer}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Product Use by Consumer"
                  showClear
                  required
                  onBlur={() => setIsDisabled(true)}
                  className="hideCaret p-dropdown-trigger"
                  disabled={isExternalAuditor(Role) || isViewer(Role)}
                />
                {isdisabled &&
                  !isESG(Role) &&
                  (!selectedProductConsumer || typeof selectedProductConsumer !== "number") ? (
                    <small className="p-error">
                      Product use By Consumer is required.
                    </small>
                  ) : (
                    ""
                  )}
              </div>
              )}
              {!isExternalAuditor(Role) && (
              <div className="item-detail">
                <label htmlFor="ProductType" className="font-bold">
                  Product Type Open Text {!isESG(Role) && <span style={{ color: "red" }}>*</span>}
                
                <span style={{ marginLeft: '5px' }}>
                  <Tooltip
                    target=".custom-target-icon5"
                    style={{ width: "15rem" }}
                  >
                    In a few words, please describe what this product is.
                    Examples: Produce Bag, Takeout Food Bowl, Can Liner
                  </Tooltip>

                  <i
                    className="custom-target-icon5  pi pi-info-circle"
                    data-pr-position="top"
                    style={{ color: "black", marginLeft: "3px" }}
                  />
                  </span>
                  </label>
                  <InputTextarea
                    id="ProductType"
                    value={product && product?.ProductType}
                    onChange={(e) => onInputChange(e, "ProductType")}
                    required
                    onBlur={() => setIsDisabled(true)}
                    disabled={isQuality(Role)&& (isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status)) ||isApproverOrViewer(Role) || isExternalAuditor(Role)}
                    rows={3}
                  />
                  {isdisabled && !isESG(Role) &&
                (!product?.ProductType || 
                typeof product?.ProductType !== "" )? (
                  <small className="p-error">
                    Product Type is required.
                  </small>
                ) : (
                  ""
                )}
              </div>
              )}
              
              {isInternalQuality(Role) && (
          <div className="item-detail">
              <label htmlFor="internal" className="font-bold">
                 HFM Internal Code <span style={{ color: "red" }}>*</span>
                </label>
                <div className="card flex justify-content-center">
                  <Dropdown
                     value={selectedHFMInternalCode}
                     id= "internal"
                     onChange={(e) => setselectedHFMInternalCode(e.value)}
                    options={hfmEnricherCodes}
                    // optionLabel="name"
                    // optionValue="id"
                    placeholder="Select from dropdown"
                    showClear
                    required={isFoundOnline}
                    disabled={isStatusInternallyReviewed(product?.Status) || isExternalAuditor(Role)}
                    onBlur={() => setIsDisabled(true)}
                  />
                  {isFoundOnline &&  !selectedHFMInternalCode && (<small className="p-error">
                      HFM Internal Code is required.
                    </small>)}
                </div>
              </div>
          )}

              {isExternalQuality(Role) && (
              <div className="item-detail">
              <label htmlFor="External" className="font-bold">
                  HFM External Code 
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="card flex justify-content-center">
                  <Dropdown
                     value={selectedHFMExternalCode}
                     id= "external"
                     onChange={(e) => setselectedHFMExternalCode(e.value)}
                    options={hfmEnricherCodes}
                    // optionLabel="name"
                    // optionValue="id"
                    placeholder="Select from dropdown"
                    showClear
                    required={isFoundOnline}
                    disabled={ isStatusExternallyReviewed(product?.Status) || isExternalAuditor(Role)}
                    onBlur={() => setIsDisabled(true)}
                  />
                  {isdisabled &&
                  !selectedHFMExternalCode &&
                  typeof selectedHFMExternalCode !== "number" &&
                  !(isQuality(Role)) ? (
                    <small className="p-error">
                      External Code is required.
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              )}

          {isQuality(Role) && (
              <div className="item-detail" style={{width: "22%"}}>
              <label htmlFor="AddComments" className="font-bold">
                Additional Comments
              <span style={{ marginLeft: '5px' }}>
                
                <i
                  className="custom-target-iconAddComments pi pi-info-circle"
                  data-pr-position="top"
                  style={{ color: "black" }}
                />
              </span>
              </label>
                <InputTextarea
                  id="AddComments"
                  placeholder="Any Additional comments"
                  value={comments || ""}
                  onChange={(e) => setComments(e.target.value)}
                  disabled={ isStatusInternallyReviewed(product?.Status) || isStatusExternallyReviewed(product?.Status) || isExternalAuditor(Role)}
                />
                </div>
                  )}

              {(isESG(Role) || isViewer(Role)) &&(
             <div className="item-detail">
                <label htmlFor="hfmcode" className="font-bold">
                  HFM Code 
                  <span>
                    <Tooltip
                      target=".custom-target-iconHFMCode"
                      style={{ width: "15rem" }}
                    >
                      Please select the HFM Code.
                    </Tooltip>

                    <i
                      className="custom-target-iconHFMCode  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black" }}
                    />
                  </span>
                </label>
                <div className="card flex justify-content-center">
                <InputText
                id="HFMCode"
                value={HFMCode}
                // onChange={(e) => onInputChange(e, "ProductType")}
                required
                onBlur={() => setIsDisabled(true)}
                disabled
              />
                </div>
              </div> 
            )}
              {/* { isStatusInReview(product?.Status) || isStatusExternallyEnriched(product?.Status) ?  */}
              { (isESG(Role) || isViewer(Role)) &&(
              <div className="item-detail">
                <label htmlFor="hfmsubcode" className="font-bold">
                  HFM Sub Code 
                  <span>
                    <Tooltip
                      target=".custom-target-iconHFMSub"
                      style={{ width: "15rem" }}
                    >
                      Please select the HFM Sub Code.
                    </Tooltip>

                      <i
                        className="custom-target-iconHFMSub pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black" }}
                      />
                    </span>
                  </label>
                  <div className="card flex justify-content-center">
                  <InputText
                  id="HFMSubCode"
                  value={HFMSubCode}
                  // onChange={(e) => onInputChange(e, "ProductType")}
                  required
                  onBlur={() => setIsDisabled(true)}
                  disabled
                />
                  </div>
                </div> )}
                {(isESG(Role) || isViewer(Role)) && (
                    <div className="item-detail">
                      <label htmlFor="name" className="font-bold">
                        Final Classification
                        
                      </label>
                      <InputTextarea
                        value={finalClassification}
                        disabled
                      />
                    </div>
                   )} 
                
            </div>

          {(isESG(Role) || isExternalAuditor(Role) || isViewer(Role) )&& (
            <div className="detail-row">
                  <div className="item-detail">
                <label htmlFor="name" className="font-bold">
                Item Found Online? 
                  <span >
                <Tooltip target=".custom-target-iconIFO" style={{width:'15rem'}} >
                If product specifications are available publicly on web, please share link where found.              
                </Tooltip>

                <i className="custom-target-iconIFO  pi pi-info-circle"
                 data-pr-position="top"
                 style={{color:'black'}}
                  />
                  </span>
                </label>
              <div className="flex align-items-center">
                  <div>
                    <RadioButton name="Yes" value={true} onChange={onIsFoundOnlineRadio}
                    checked={isFoundOnline === true} disabled={isExternalAuditor(Role) || isViewer(Role)}/>
                    <label htmlFor="item1" className="p-radiobutton-label">Yes</label> 
                  </div>
                <div style={{ marginRight: '10px' }} /> 
                  <div>
                    <RadioButton name="No" value={false} onChange={onIsFoundOnlineRadio}
                    checked={isFoundOnline === false} disabled={isExternalAuditor(Role) || isViewer(Role)} />
                    <label htmlFor="item2" className="p-radiobutton-label">No</label> 
                  </div>
                </div>
              </div>
                  
              <div className="item-detail" >
                <label htmlFor="WebsiteAddress" className="font-bold">
                    Website Address 
                </label>

                  <InputTextarea
                    id="WebsiteAddress"
                    value={websiteAddress || ""}
                    onChange={(e) => setWebsiteAddress(e.target.value)}
                    placeholder="Copy and Paste Url Where Item is Found"
                    required={isFoundOnline}
                    disabled={!isFoundOnline || isExternalAuditor(Role) || isViewer(Role)}
                    className="link-text flex-grow-1"
                    onDoubleClick={handleDoubleClick}
                  />
                </div>
                <div className="item-detail" >
                <label htmlFor="Notes" className="font-bold">
                  Notes 
                <span style={{ marginLeft: '5px' }}>
                  <Tooltip
                    target=".custom-target-iconNotes"
                    style={{ width: "15rem" }}
                  >
                    If material type selected is OTHER, please list specific material type here. ​
                    If product is now inactive, please indicate as such
                  </Tooltip>

                  <i
                    className="custom-target-iconNotes pi pi-info-circle"
                    data-pr-position="top"
                    style={{ color: "black" }}
                  />
                </span>
                </label>
                  <InputTextarea
                    id="Notes"
                    className="flex-grow-1"
                    placeholder="Open text for comments"
                    value={notes || ""}
                    onChange={(e) => setNotes(e.target.value)}
                    disabled={isExternalAuditor(Role)}
                  />
                </div>
                {!isExternalAuditor(Role) && (
                <div className="item-detail" >
                    <label htmlFor="AddComments" className="font-bold">
                      Additional Comments
                    <span style={{ marginLeft: '5px' }}>
                      
                    </span>
                    </label>
                    <InputTextarea
                      id="AddComments"
                      className="flex-grow-1"
                      placeholder="Any Additional comments"
                      value={comments || ""}
                      onChange={(e) => setComments(e.target.value)}
                      disabled={isViewer(Role)}
                    />
                  </div>
                )}
                { (isESG(Role) || isViewer(Role))&& ( 
                <div className="item-detail" >
                <label htmlFor="name" className="font-bold">
                    HFM Final Code 
                    <span>
                      <Tooltip
                        target=".custom-target-iconHFMFinalCode"
                        style={{ width: "15rem" }}
                      >
                        Please select the HFM Final Code.
                      </Tooltip>

                    <i
                      className="custom-target-iconHFMFinalCode  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black" }}
                    />
                  </span>
                </label>
                <InputText
                id="HFMFinalCode"
                value={HFMFinalCode}
                // onChange={(e) => onInputChange(e, "ProductType")}
                required
                onBlur={() => setIsDisabled(true)}
                disabled
              />
              </div>
              )}
           </div>
                  )}
             {isExternalAuditor(Role) && (
              <div className="detail-row">
                <div className="item-detail">
                <label htmlFor="hfmcode" className="font-bold">
                  HFM Code 
                  <span>
                    <Tooltip
                      target=".custom-target-iconHFMCode"
                      style={{ width: "15rem" }}
                    >
                      Please select the HFM Code.
                    </Tooltip>

                    <i
                      className="custom-target-iconHFMCode  pi pi-info-circle"
                      data-pr-position="top"
                      style={{ color: "black" }}
                    />
                  </span>
                </label>
                <div className="card flex justify-content-center">
                <InputText
                id="HFMCode"
                value={HFMCode}
                // onChange={(e) => onInputChange(e, "ProductType")}
                required
                onBlur={() => setIsDisabled(true)}
                disabled
              />
                </div>
              </div>
              <div className="item-detail">
                <label htmlFor="hfmsubcode" className="font-bold">
                  HFM Sub Code 
                  <span>
                    <Tooltip
                      target=".custom-target-iconHFMSub"
                      style={{ width: "15rem" }}
                    >
                      Please select the HFM Sub Code.
                    </Tooltip>

                      <i
                        className="custom-target-iconHFMSub pi pi-info-circle"
                        data-pr-position="top"
                        style={{ color: "black" }}
                      />
                    </span>
                  </label>
                  <div className="card flex justify-content-center">
                  <InputText
                  id="HFMSubCode"
                  value={HFMSubCode}
                  // onChange={(e) => onInputChange(e, "ProductType")}
                  required
                  onBlur={() => setIsDisabled(true)}
                  disabled
                />
                  </div>
                </div>
                {/* <div className="item-detail">
                      <label htmlFor="name" className="font-bold">
                        Final Classification
                      </label>
                      <InputTextarea
                        value={finalClassification}
                        disabled
                      />
                    </div> */}
                </div>
               )}     

            <div className="detail-row">
              {(isESG(Role) || isViewer(Role))&& (
             <div className="item-detail">
            <label htmlFor="HFMCode" className="font-bold">
            Initial HFM Code
            </label>
            <span style={{ marginLeft: '5px' }}>
              <Tooltip
                target=".custom-target-iconHFMTempCode"
                style={{ width: "15rem" }}
              >
               Initial HFM Code
              </Tooltip>

              <i
                className="custom-target-iconHFMTempCode  pi pi-info-circle"
                data-pr-position="top"
                style={{ color: "black", marginLeft: "3px" }}
              />
              </span>
              <InputText
                id="HFMCode"
                value={TempHFMCode}
                // onChange={(e) => onInputChange(e, "ProductType")}
                required
                onBlur={() => setIsDisabled(true)}
                disabled
              />
          </div>
              )}
            {(isESG(Role)|| isViewer(Role)) &&  (
             <div className="item-detail">
                <label htmlFor="TEMPHFMSubCode" className="font-bold">
            Initial HFM Sub Code 
            </label>
            <span style={{ marginLeft: '5px' }}>
              <Tooltip
                target=".custom-target-iconsubcode"
                style={{ width: "15rem" }}
              >
               Initial HFM Sub Code
              </Tooltip>

              <i
                className="custom-target-iconsubcode  pi pi-info-circle"
                data-pr-position="top"
                style={{ color: "black", marginLeft: "3px" }}
              />
              </span>
              <InputText
                id="HFMSubCode"
                value={TempHFMSubCode}
                // onChange={(e) => onInputChange(e, "ProductType")}
                required
                onBlur={() => setIsDisabled(true)}
                disabled
              />
            
              </div>
            )}
            {(isESG(Role)|| isViewer(Role)) &&  (
             <div className="item-detail" >
                <label htmlFor="HFMLogic" className="font-bold">
            HFM Logic
            </label>
            <span style={{ marginLeft: '5px' }}>
              <Tooltip
                target=".custom-target-iconHFMLogic"
                style={{ width: "15rem" }}
              >
               HFM Logic
              </Tooltip>

              <i
                className="custom-target-iconHFMLogic  pi pi-info-circle"
                data-pr-position="top"
                style={{ color: "black", marginLeft: "3px" }}
              />
              </span>
              <InputText
                id="HFMLogic"
                value={hfmLogic}
                // onChange={(e) => onInputChange(e, "hfmLogic")}
                required
                onBlur={() => setIsDisabled(true)}
               disabled
              />
              </div>
            )}

            {(isESG(Role) || isViewer(Role))&& (
           <div className="item-detail" >
              <label htmlFor="internal" className="font-bold">
                 HFM Internal Code 
                </label>
                <div className="card flex justify-content-center">
                  <Dropdown
                     value={selectedHFMInternalCode}
                     id= "internal"
                     onChange={(e) => setselectedHFMInternalCode(e.value)}
                    options={hfmEnricherCodes}
                    // optionLabel="name"
                    // optionValue="id"
                    placeholder="Select from dropdown"
                    showClear
                    required={isFoundOnline}
                    disabled={isViewer(Role)}
                    onBlur={() => setIsDisabled(true)}
                  />
                  {/* {isFoundOnline && !selectedHFMInternalCode && (<small className="p-error">
                      HFM Internal Code is required.
                    </small>)} */}
                </div>
              </div>
          )}
              {(isESG(Role) || isViewer(Role))&&(
              <div className="item-detail" >
              <label htmlFor="External" className="font-bold">
                  HFM External Code 
                  
                </label>
                <div className="card flex justify-content-center">
                  <Dropdown
                     value={selectedHFMExternalCode}
                     id= "external"
                     onChange={(e) => setselectedHFMExternalCode(e.value)}
                    options={hfmEnricherCodes}
                    // optionLabel="name"
                    // optionValue="id"
                    placeholder="Select from dropdown"
                    showClear
                    required={isFoundOnline}
                     disabled={isViewer(Role) }
                    onBlur={() => setIsDisabled(true)}
                  />
                  {/* {isdisabled &&
                  !selectedHFMExternalCode &&
                  typeof selectedHFMExternalCode !== "number" &&
                  !(isQuality(Role)) ? (
                    <small className="p-error">
                      External Code is required.
                    </small>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
              )}
              
            </div>


            </TabPanel>
            {(isExternalAuditor(Role) || isESG(Role) || isViewer(Role)) && 
            <TabPanel header="Audit Trail">
              <AuditTrail 
                Role={Role} 
                auditData={auditData} 
                dt={auditDataTable}
                loading={isAuditLoading}
               />
            </TabPanel>}
            {/* <TabPanel header="Return to Supplier Window" >
              <InputTextarea
                  inputid="comment"
                  name="comment"
                  rows={4}
                  cols={30}
                  placeholder="Add comment to Supplier..." 
                
              />
            </TabPanel> */}

            {/* <TabPanel header="Comment History" >
              <div className="field">
                    <label htmlFor="comment" className="font-bold">
                        Add Comment
                    </label>
                    <InputTextarea id="comment" value={selectedComment}  required onChange={(e) => setSelectedComment(e.target.value)} 
                    onBlur={()=>setIsDisabled(true)} />
                    {isdisabled && selectedComment === '' && (
                    <small className="p-error">Comment is required for rejected case.</small>
                  )}
                </div>
                {product?.Status?.Status === 'In Progress' && product?.itemReviewHistory.length > 0 && (
                 
                  <table>
                    <thead>
                      <tr key={"header"}>
                        {Object.keys(rejectedComments[0])?.map((data) => (
                          <th>{data}</th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ border: "1px solid black" }}>
                      {rejectedComments?.map((item, i) => (
                        <tr key={i}>
                          {Object.values(item).map((val) => (
                            <td
                              style={{
                                borderRight: "1px solid black",
                                paddingRight: "25px",
                                borderBottom: "1px solid black",
                              }}
                            >
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                
            )}
            </TabPanel> */}

            {errorDialog && (
              <TabPanel header="Error Message">
                {errorDialog && (
                  <table>
                    <thead>
                      <tr key={"header"}>
                        {Object.keys(errorData[0])?.map((data) => (
                          <th>{data}</th>
                        ))}
                      </tr>
                    </thead>

                    <tbody style={{ border: "1px solid black" }}>
                      {errorData.map((item, i) => (
                        <tr key={i}>
                          {/* {console.log("Values :-",Object.values(item))} */}
                          {Object.values(item).map((val) => (
                            <td
                              style={{
                                borderRight: "1px solid black",
                                paddingRight: "25px",
                                borderBottom: "1px solid black",
                              }}
                            >
                              {val}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </TabPanel>
            )}
          </TabView>
        </Card>

      </Dialog>
      <Dialog
        visible={importDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Import CSV"
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <form>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChangeFile}
          />

          <button
            style={{ backgroundColor: "#003e7e", color: "white" }}
            onClick={(e) => {
              handleOnSubmitFile(e);
            }}
          >
            IMPORT CSV
          </button>
        </form>

        <br />

        {error ? (
          error
        ) : (
          <table>
            <thead>
              <tr key={"header"}>
                {headerKeys.map((key) => (
                  <th>{key}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {array.map((item) => (
                <tr key={item.id}>
                  {Object.values(item).map((val) => (
                    <td>{val}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Dialog>

      {/* <Dialog visible={errorDialog}  style={{ width: '34rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Error Message" modal className="p-fluid" onHide={errHideDialog}>
  
   { errorDialog &&  <table>
          <thead>
            <tr key={"header"}>
              
              {Object.keys(errorData[0])?.map((data) => (
                <th>{data}</th>
              ))}
            </tr>
          </thead>
  
          <tbody style={{border:"1px solid black"}}>
            {errorData.map((item,i) => (
              <tr key={i}>
                {Object.values(item).map((val) => (
                  <td style={{borderRight:"1px solid black",paddingRight:'25px'}}>{val}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
}
            </Dialog> */}

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              Are you sure you want to delete <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>Are you sure you want to delete the selected products?</span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
