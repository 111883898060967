import { DataTable } from "primereact/datatable";
import { isApprover, isApproverOrViewer, isViewer, isQuality, isSupplier, isExternalEnricher, isInternalEnricher, isEnricher, isExternalQuality, isESG, isExternalAuditor, isAdmin } from "../utils/roleValidator";
import { Column } from "primereact/column";
import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";

function ItemsDataTable(props) {
  const [pageCount, setPageCount] = useState(1);
  const prevRows = useRef(10);
  const onPage = (event) => {
    event = persistFields(event);

    if (event.page === 0) {
      event.firstPage = true; // if true go to first page
    } else if (event.page === event.pageCount - 1) {
      event.lastPage = true; // if true go to last page
    } else if (props.lazyState.page > event.page) {
      event.before = props.products[0]["Id"]; // if != null, go to prev page
    } else if (props.lazyState.page < event.page) {
      event.after = props.products[props.products.length - 1]["Id"]; // if != null go to next page
    }
    props.setlazyState(event);
  };

  const onSort = (event) => {
    event = persistFields(event);
    event.first = 0;
    event.firstPage = true;
    props.setlazyState(event);
  };

  const onFilter = (event) => {
    event = persistFields(event);
    event.firstPage = true;
    props.setlazyState(event);
  };

  let attributes = {
    ref: props.dt,
    value: props.products,
    dataKey: "Id",
    header: props.header,
    loading: props.loading,
    scrollable: true,
    scrollHeight: "55vh"
  };

  if (!isExternalAuditor(props.Role)) {
    attributes = {
      ...attributes,
      paginator: true,
      rows: props.lazyState.rows,
      rowsPerPageOptions: [5, 10, 25],
      paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} products",
      lazy: true,
      first: props.lazyState.first,
      totalRecords: props.totalRecords,
      onPage: onPage,
      onSort: onSort,
      sortField: props.lazyState.sortField,
      sortOrder: props.lazyState.sortOrder,
      onFilter: onFilter,
      filters: props.lazyState.filters,
      removableSort: true,
    };
  }
  useEffect(() => {
    if (!props.lazyState.pageCount || prevRows != props.lazyState.rows) {
      let calculatedTotalPages = Math.ceil(props.totalRecords / props.lazyState.rows);
      if (calculatedTotalPages === 0) {
        setPageCount(1);
      } else {
        setPageCount(calculatedTotalPages);
      }
    } else {
      setPageCount(props.lazyState.pageCount);
    }
    if (prevRows != props.lazyState.rows) {
      prevRows.current = props.lazyState.rows;
    }
  }, [props.lazyState.pageCount, props.totalRecords, props.lazyState.rows]);
  if (isEnricher(props.Role) || isESG(props.Role) || isQuality(props.Role)) {
    attributes.paginatorTemplate = "FirstPageLink PrevPageLink NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown";
    attributes.currentPageReportTemplate = "Showing page "+(props.lazyState.page+1)+" of "+pageCount+" pages and {totalRecords} total Items";
  }

  if (isExternalAuditor(props.Role)) {
    attributes.emptyMessage = "Please Enter an Item Number";
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";
    return moment(dateString).format('YYYY-MM-DD HH:mm:ss'); 
  };

  const getStatusText = (status) => {
    if (status === "Internally Reviewed") {
      return "Internally Enriched Validated";
    } else if (status === "ExternallyReviewed") {
      return "Externally Enriched Validated";
    } else if (status == null) {
      return "";
    } else {
      return status;
    }
  };

  const persistFields = (event) => {
    event.firstPage = false;
    event.before = null;
    event.after = null;
    event.lastPage = false;
    if (!event.hasOwnProperty("page") ) {
      event.page = Math.floor(event.first / event.rows);
    }
    return event;
  };
   const statusBodyTemplate = (rowData) => {
    return getStatusText(rowData.Status?.Status);
  };

  const finalClassificationTemplate = (rowData) => {
    return  rowData.HFMFinal || rowData.HfmInternalCode  || rowData.HfmExternalCode || rowData.HFMLogic || 'N/A';
  };

  const defaultColumnStyle = {
    minWidth: "12rem",
    padding: "0.5rem",
    textAlign: "left",
    verticalAlign: "top",
    whiteSpace: "pre-wrap", /* Ensures the text wraps within the container */
    wordWrap: "break-word", /* Breaks long words to prevent overflow */
    overflowWrap: "break-word" /* Ensures long words break within the container */
  };
  

  const notesBodyTemplate = (rowData) => {
    return <div className="notes-column">{rowData.Notes}</div>;
  };

  const formatUrl = (websiteAddress) => {
    return websiteAddress?.startsWith('http://') || websiteAddress?.startsWith('https://')
      ? websiteAddress
      : `http://${websiteAddress}`;
  };
  
  const urlTemplate = (rowData) => {
    const websiteAddress = rowData.WebsiteAddress;
    const url = formatUrl(websiteAddress);
  
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {websiteAddress}
      </a>
    );
  };
  

  return (
    <DataTable {...attributes}>
      <Column
        body={props.viewDetailTemplate}
        style={{ ...defaultColumnStyle, minWidth: "11rem" }}
      ></Column>
      {(isSupplier(props.Role) || isEnricher(props.Role)) && (
        <Column
          field="MaterialType.Name"
          header="Material Type"
          sortable
          hidden
          style={defaultColumnStyle}
        ></Column>
      )}
      {(isAdmin(props.Role) || isApproverOrViewer(props.Role) || isEnricher(props.Role) || isQuality(props.Role) || isESG(props.Role) || isExternalAuditor(props.Role)) && (
        <Column
          field="Supplier.Name"
          header="Supplier Name"
          sortable={!isExternalAuditor(props.Role)}
          style={defaultColumnStyle}
        ></Column>
      )}
      {(isExternalAuditor(props.Role) || isESG(props.Role) )&& (
        <Column
          field="Manufacturer.ManufacturerName"
          header="Manufacturer Name"
          sortable={!isExternalAuditor(props.Role)}
          style={defaultColumnStyle}
        ></Column>
      )}
      {(isSupplier(props.Role) || isEnricher(props.Role) || isESG(props.Role) || isViewer(props.Role)) && (
        <Column
          field="VendorPartNumber"
          header="Supplier Part Number"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      <Column
        field="ItemNumber"
        header="Item Number"
        sortable={!isExternalAuditor(props.Role)}
        style={{ ...defaultColumnStyle, minWidth: isViewer(props.Role) ? "8rem" : "12rem" }}
      ></Column>
      <Column
        field="ItemDescription"
        header="Item Description"
        sortable={!isExternalAuditor(props.Role)}
        style={{ ...defaultColumnStyle, minWidth: (isApproverOrViewer(props.Role) || isQuality(props.Role) || isESG(props.Role)) ? "16rem" : "12rem" }}
      ></Column>
      {!isExternalAuditor(props.Role) && (
        <Column
          field="PrimaryCategory"
          header="Primary Category Description"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {!isExternalAuditor(props.Role) && (
        <Column
          field="SecondaryCategory"
          header="Secondary Category Description"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {!isExternalAuditor(props.Role) && (
        <Column
          field="SubCategory"
          header="Sub Category Description"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {(isApproverOrViewer(props.Role) || isQuality(props.Role) || isESG(props.Role) || isExternalAuditor(props.Role)) && (
        <Column
          field="MaterialType.Name"
          header="Material Type"
          sortable={!isExternalAuditor(props.Role)}
          style={defaultColumnStyle}
        ></Column>
      )}
      {(isESG(props.Role) || isExternalAuditor(props.Role) || isViewer(props.Role)) && (
        <Column
          field="MaterialSubType.Name"
          header="Material Sub Type"
          sortable={!isExternalAuditor(props.Role)}
          style={defaultColumnStyle}
        ></Column>
      )}
      {/* {isViewer(props.Role) && (
        <Column
          field="itemHfmMapping.HfmCode"
          header="HFM Code"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {isViewer(props.Role) && (
        <Column
          field="itemHfmMapping.HfmSubCode"
          header="HFM Sub Code"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )} */}
      {(isSupplier(props.Role) || isEnricher(props.Role)) && (
        <Column
          field="BuyUnitOfMeasure"
          header="Buy Unit Of Measure"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {isSupplier(props.Role) && (
        <Column
          field="BuyPackDescription"
          header="Buy Pack Description"
          sortable
          style={defaultColumnStyle}
        />
      )}
      {isSupplier(props.Role) && (
        <Column
          field="GrossProductWeight"
          header="Gross Product Weight"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {!isExternalAuditor(props.Role) && (
        <Column
          field="Status.Status"
          header="Status"
          body={statusBodyTemplate}
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {/* {isViewer(props.Role) && (
        <Column
          field="ModifiedDate"
          header="Item Submitted On"
          body={props.itemSubmittedDate}
          sortable
          style={defaultColumnStyle}
        ></Column>
      )} */}
      {!isExternalAuditor(props.Role) && (
        <Column
          field="SubmittedReviewBy"
          header="Submitted By"
          body={props.SubmittedReviewBy}
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {!isExternalAuditor(props.Role) && (
        <Column
          field="SubmittedDate"
          header="Submitted Date"
          body={(rowData) => formatDate(rowData.SubmittedDate)}
          sortable
          style={{ ...defaultColumnStyle, textAlign: 'center' }}
        ></Column>
      )}
      {!isExternalAuditor(props.Role) && (
        <Column
          field="ModifiedBy"
          header="Modified By"
          body={props.SubmittedDate}
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {isApprover(props.Role) && (
        <Column
          field="LastEditDate"
          header="Last Edit Date"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {(isESG(props.Role) || isViewer(props.Role))&& (
        <Column
          field="HFMLogic"
          header="HFM Logic"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {isExternalAuditor(props.Role) && (
        <Column
          field="WebsiteAddress"
          header="Website Address"
          body={urlTemplate}
          style={defaultColumnStyle}
        />
      )}
      {isExternalAuditor(props.Role) && (
        <Column
          field="Notes"
          header="Notes"
          body={notesBodyTemplate}
          style={{ ...defaultColumnStyle }}
        />
      )}
      {(isESG(props.Role)|| isViewer(props.Role)) && (
        <Column
          field="HfmInternalCode"
          header="HFM Internal"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {(isESG(props.Role) || isViewer(props.Role))&& (
        <Column
          field="HfmExternalCode"
          header="HFM External"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {(isViewer(props.Role) || isESG(props.Role)|| isViewer(props.Role)) && (
        <Column
          field="HFMFinal"
          header="HFM Final"
          sortable
          style={defaultColumnStyle}
        ></Column>
      )}
      {/* {isExternalAuditor(props.Role) && (
        <Column
          body={finalClassificationTemplate}
          header="Final Classification"
          style={{ ...defaultColumnStyle, textAlign: 'center' }}
        ></Column>
      )} */}
    </DataTable>
  );
}

export default ItemsDataTable;
