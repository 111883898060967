import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import { getAccessToken } from "../utils/http";
import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { showErrorToastNullable, showSuccessToast } from "../services/ToastService";
import { Dialog } from "primereact/dialog";
import { groupSupplier, getManufacturersList, saveSupplierGroupDetails, saveSupplierDetails, getSuppliersList, saveManufacturerDetails, getNominatedByList, getSupplier } from "../services/UserManagementService";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { MultiSelect } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from "primereact/calendar";

export default function SupplierManagement() {
  const token = getAccessToken(); 
  const toast = useRef(null);
  const [showInactiveUsers, setShowInactiveUsers] = useState(false);
  const sgdt = useRef(null);
  const [count, setCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSgLoading, setIsSgLoading] = useState(false);
  const [sgGlobalFilterValue, setSgGlobalFilterValue] = useState("");
  const [supplierGroupDialog, setSupplierGroupDialog] = useState(false);
  const [supplierGroupName, setSupplierGroupName] = useState("");
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [isSupplierGroupActive, setIsSupplierGroupActive] = useState(null);
  const [supplierList, setSupplierList] = useState([]);
  const [sgId, setSgId] = useState(0);
  const [isAddingSg, setIsAddingSg] = useState(false);
  const [expandedSgRows, setExpandedSgRows] = useState(null);
  const [sgFilters, setSgFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const sdt = useRef(null);
  const [isSLoading, setIsSLoading] = useState(false);
  const [sGlobalFilterValue, setSGlobalFilterValue] = useState("");
  const [supplierDialog, setSupplierDialog] = useState(false);
  const [supplierName, setSupplierName] = useState("");
  const [selectedSupplierGroup, setSelectedSupplierGroup] = useState(null);
  const [supplierGroupList, setSupplierGroupList] = useState([]);
  const [nominatedByList, setNominatedByList] = useState([]);
  const [isSupplierActive, setIsSupplierActive] = useState(null);
  const [sId, setSId] = useState(0);
  const [isAddingS, setIsAddingS] = useState(false);
  const [expandedSRows, setExpandedSRows] = useState(null);
  const [nominatedBy, setNominatedBy] = useState(null);
  const [sFilters, setSFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });
  const mdt = useRef(null);
  const [isMLoading, setIsMLoading] = useState(false);
  const [mGlobalFilterValue, setMGlobalFilterValue] = useState("");
  const [manufacturerDialog, setManufacturerDialog] = useState(false);
  const [manufacturerName, setManufacturerName] = useState("");
  const [manufacturerList, setManufacturerList] = useState([]);
  const [supplierShortList, setSupplierShortList] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [isManufacturerActive, setIsManufacturerActive] = useState(null);
  const [mId, setMId] = useState(0);
  const [isAddingM, setIsAddingM] = useState(false);
  const [expandedMRows, setExpandedMRows] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [validEndDate, setValidEndDate] = useState(false);
  const [contactInfo, setContactInfo] = useState("");
  const [mFilters, setMFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const onSgGlobalFilterChange = (e) => {
    setSgGlobalFilterValue(e.target.value);
  };

  const onGlobalFilterEnter = (e) => {
    if (e.key === 'Enter') {
      if (activeIndex === 0) {
        setSgFilters(prev => {
          let filter = JSON.parse(JSON.stringify(prev));
          filter['global'].value = sgGlobalFilterValue;
          return filter;
        });
      } else if (activeIndex === 1) {
        setSFilters(prev => {
          let filter = JSON.parse(JSON.stringify(prev));
          filter['global'].value = sGlobalFilterValue;
          return filter;
        });
      } else {
        setMFilters(prev => {
          let filter = JSON.parse(JSON.stringify(prev));
          filter['global'].value = mGlobalFilterValue;
          return filter;
        });
      }
    }
  };
  
  const onGlobalFilterBlur = (e) => {
    if (activeIndex === 0) {
      if (sgGlobalFilterValue === "") {
        setSgFilters(prev => {
          let filter = JSON.parse(JSON.stringify(prev));
          filter['global'].value = "";
          return filter;
        });
      }
    } else if (activeIndex === 1){
      if (sGlobalFilterValue === "") {
        setSFilters(prev => {
          let filter = JSON.parse(JSON.stringify(prev));
          filter['global'].value = "";
          return filter;
        });
      }
    } else {
      if (mGlobalFilterValue === "") {
        setMFilters(prev => {
          let filter = JSON.parse(JSON.stringify(prev));
          filter['global'].value = "";
          return filter;
        });
      }
    }
  };

  const onSGlobalFilterChange = (e) => {
    setSGlobalFilterValue(e.target.value);
  };

  const onMGlobalFilterChange = (e) => {
    setMGlobalFilterValue(e.target.value);
  };
  
  useEffect(() => {
    if (startDate) {
      const newDate = new Date(startDate.getTime());
  
      // Add one day to the date
      newDate.setDate(newDate.getDate() + 1);
      
      setMinDate(newDate);
    } else {
      setMinDate(null);
    }
  }, [startDate]);
  
  useEffect(() => {
    if (minDate == null || endDate == null) {
      setValidEndDate(true);
    } else {
      setValidEndDate(minDate <= endDate);
    }
  }, [minDate, endDate]);

  const clearSg = () => {
    setSupplierGroupName("");
    setSelectedSuppliers([]);
    setSelectedManufacturers([]);
    setIsSupplierGroupActive(null);
    setSgId("");
  };
  const clearS = () => {
    setSupplierName("");
    setSelectedSupplierGroup("");
    setSelectedManufacturers([]);
    setNominatedBy(null);
    setIsSupplierActive(null);
    setSId("");
  };
  const clearM = () => {
    setManufacturerName("");
    setSelectedSupplierGroup([]);
    setSelectedSuppliers([]);
    setStartDate(null);
    setEndDate(null);
    setIsManufacturerActive(null);
    setMId("");
  };

  useEffect(() => {
    loadSupplierGroupsList();
    loadManufacturersList();
    loadSuppliersList();
    loadNominatedByList();
  }, [count, showInactiveUsers]);

  const exportSupplierGroups = () => {
    sgdt.current.exportCSV();
  };
  const exportSuppliers = () => {
    sdt.current.exportCSV();
  };
  const exportManufacturers = () => {
    mdt.current.exportCSV();
  };
  
  const loadSupplierGroupsList = () => {
    setIsSgLoading(true);
    groupSupplier(showInactiveUsers, token,(res) => {
      let newSupplierGroupList = res?.data.map(sg => {
        return {
          ...sg,
          SupplierNames: concatSupplierNames(sg),
          ManufacturerNames: concatManufacturerNames(sg),
          isActiveLabel: isActiveTemplate(sg)
        };
      });
      setSupplierGroupList(newSupplierGroupList);
      setIsSgLoading(false);
    },
    (error) => {
      showErrorToastNullable(toast, error?.response?.data?.message);
      setIsSgLoading(false);
    });
  };

  const loadSuppliersList = () => {
    setIsSLoading(true);
    getSuppliersList(showInactiveUsers, token,(res) => {
      let newSupplierList = res?.data.map(supplier => { 
        return {
          ...supplier,
          ManufacturerNames: concatManufacturerNames(supplier),
          isActiveLabel: isActiveTemplate(supplier)
        };
      });
      setSupplierList(newSupplierList);
      setIsSLoading(false);
    },
    (error) => {
      showErrorToastNullable(toast, error?.response?.data?.message);
      setIsSLoading(false);
    });
  };

  const loadManufacturersList = () => {
    
    setIsMLoading(true);
    getManufacturersList(showInactiveUsers, token,(res) => {
      let newManufacturerList = res?.data?.map(m => {
        return {
          ...m,
          SupplierGroupNames: concatSupplierGroups(m),
          SupplierNames: concatSupplierNames(m),
          Emails: concatEmails(m),
          isActiveLabel: isActiveTemplate(m)
        };
      });
      setManufacturerList(newManufacturerList);
      setIsMLoading(false);
    },
    (error) => {
      showErrorToastNullable(toast, error?.response?.data?.message);
      setIsMLoading(false);
    });
  };

  const loadSupplierShortList = async (suppliergroups) => {
    if (suppliergroups === null || suppliergroups.length === 0) {
      setSupplierShortList(supplierList);
      return supplierList;
    }
    let newSupplierShortList = []
    for (let i = 0; i < suppliergroups.length; i++) {
      await getSupplier(suppliergroups[i], token, (res) => {
        newSupplierShortList.push.apply(newSupplierShortList, res?.data[0]?.suppliers);
      }, (error) => {
        showErrorToastNullable(toast, error?.response?.data?.message);
        console.log(error);
      });
    }
    setSupplierShortList(newSupplierShortList);
    setSelectedSuppliers(prev => prev.filter(id => newSupplierShortList.map(s => s.id).includes(id)));
    return newSupplierShortList;
  };

  useEffect(() => {
    if (activeIndex == 2) {
      loadSupplierShortList(selectedSupplierGroup);
    }
  }, [selectedSupplierGroup,activeIndex]);
  
  const loadNominatedByList = () => {
    setIsSLoading(true);
    getNominatedByList(token,(res) =>
    {
      let newNominatedByList = res?.data?.map(n => {
        return {
          ...n,
          isActiveLabel: isActiveTemplate(n)
        };
      });
      setNominatedByList(newNominatedByList);
      setIsSLoading(false);
    },(error) => {
      showErrorToastNullable(toast, error?.response?.data?.message);
      setIsSLoading(false);
    });
  };
  
  const viewSupplierGroup = (data) => {
    setSupplierDialog(false);
    setManufacturerDialog(false);
    setIsAddingSg(false);
    setSupplierGroupName(data.name);
    setSelectedSuppliers(data.suppliers?.map(supplier => supplier.id));
    setSelectedManufacturers(data.manufacturers?.map(manufacturer => manufacturer.manufacturerId));
    setIsSupplierGroupActive(data.isActive);
    setSgId(data.id);
    setSupplierGroupDialog(true);
  };

  const viewSupplier = (data) => {
    setSupplierGroupDialog(false);
    setManufacturerDialog(false);
    setIsAddingS(false);
    setSupplierName(data.name);
    setSelectedSupplierGroup(data.supplierGroupId);
    setSelectedManufacturers(data.manufacturers?.map(manufacturer => manufacturer.manufacturerId));
    setNominatedBy(data.nominatorId);
    setIsSupplierActive(data.isActive);
    setSId(data.id);
    setSupplierDialog(true);
  };

  const viewManufacturer = async (data) => {
    setSupplierGroupDialog(false);
    setSupplierDialog(false);
    setIsAddingM(false);
    setManufacturerName(data?.manufacturerName);
    let suppliergroups = data?.supplierGroups?.map(sg => sg.id);
    setSelectedSupplierGroup(suppliergroups);
    let newSupplierShortList = await loadSupplierShortList(suppliergroups);
    setSelectedSuppliers(
      data.suppliers?.map(supplier => supplier.id)
      .filter(id => newSupplierShortList.map(s => s.id).includes(id))
    );
    setStartDate(data.startDate ? new Date(data.startDate) : null);
    setEndDate(data.endDate ? new Date(data.endDate) : null);
    setContactInfo(data.contactInfo);
    setIsManufacturerActive(data.isActive);
    setMId(data.manufacturerId);
    setManufacturerDialog(true);
  };

  const hideSupplierGroup = () => {
    setSupplierGroupDialog(false);
  };
  const hideSupplier = () => {
    setSupplierDialog(false);
  };
  const hideManufacturer = () => {
    setManufacturerDialog(false);
  };

  const showAddSupplierGroup = () => {
    setIsAddingSg(true);
    setSupplierDialog(false);
    setManufacturerDialog(false);
    clearSg();    
    setSupplierGroupDialog(true);
  };

  const showAddSuppler = () => {
    setIsAddingS(true);
    setSupplierGroupDialog(false);
    setManufacturerDialog(false);
    clearS();
    setSupplierDialog(true);
  };

  const showAddManufacturer = () => {
    setIsAddingM(true);
    setSupplierGroupDialog(false);
    setSupplierDialog(false);
    clearM();
    setManufacturerDialog(true);
  };


  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <Button 
        label={activeIndex == 0 ? "Add Supplier Group" : activeIndex == 1 ? "Add Supplier" : "Add Manufacturer"}
        style={{backgroundColor:'#003e7e'}}
        onClick={activeIndex == 0 ? showAddSupplierGroup :  activeIndex == 1 ? showAddSuppler : showAddManufacturer}/>
      <div className="flex flex-wrap gap-2 align-items-center justify-content-end">
        <div className="flex flex-wrap gap-2  align-items-center">
          <InputSwitch 
            inputId="showInactive" 
            checked={showInactiveUsers} 
            onChange={(e) => setShowInactiveUsers(e.value)} 
          />
          <label htmlFor="showInactive">{activeIndex == 0 ? "Show Inactive Supplier Groups" :  activeIndex == 1 ? "Show Inactive Suppliers" : "Show Inactive Manufacturers"}</label>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={activeIndex == 0 ? sgGlobalFilterValue : activeIndex == 1 ? sGlobalFilterValue : mGlobalFilterValue}
              onInput={activeIndex == 0 ? onSgGlobalFilterChange : activeIndex == 1 ? onSGlobalFilterChange : onMGlobalFilterChange}
              placeholder="Search..."
              onKeyPress={onGlobalFilterEnter}
              onBlur={onGlobalFilterBlur}
            />
          </span>
          <Button
            label="Export"
            icon="pi pi-download"
            style={{ backgroundColor: "#003e7e" }}
            onClick={activeIndex == 0 ? exportSupplierGroups : activeIndex == 1 ? exportSuppliers : exportManufacturers}
          />
        </div>
      </div>
    </div>
  );

  const viewDetailsTemplate = (data) => {
    return (
      <Button
        label="View/Edit"
        icon="pi pi-pencil"
        rounded
        style={{ backgroundColor: "#003e7e" }}
        onClick={() => activeIndex == 0 ? viewSupplierGroup(data) : activeIndex == 1 ? viewSupplier(data) : viewManufacturer(data)}
      />
    );
  };

  const saveSupplierGroup = () => {
    let body = {
      id: isAddingSg ? null : sgId,
      name: supplierGroupName,
      suppliers: selectedSuppliers,
      manufacturers: selectedManufacturers,
      isActive: isAddingSg ? true : isSupplierGroupActive,
      showInactive: showInactiveUsers,
    };

    saveSupplierGroupDetails(body, token, (res) => {
      hideSupplierGroup();
      setIsAddingSg(false);
      clearSg();
      setCount(prev => prev + 1);
      showSuccessToast(toast, isAddingSg ? "Supplier Group Created" : "Supplier Group Updated");
    }, (e) => {
      hideSupplierGroup();
      setIsAddingSg(false);
      clearSg();
      if (e?.response?.status === 401) {
        showErrorToastNullable(toast, "Session timeout! Please logout and login again");
      } 
      else if (e?.response?.status === 500) {
        showErrorToastNullable(toast, "Error while saving records");
      }else {
        showErrorToastNullable(toast, e?.response?.data?.Message);
      }
    });
  };

  const saveSupplier = () => {
    let body = {
      id: isAddingS ? null : sId,
      name: supplierName,
      supplierGroupId: selectedSupplierGroup,
      manufacturers: selectedManufacturers,
      nominatorId: nominatedBy,
      isActive: isAddingS ? true : isSupplierActive,
      showInactive: showInactiveUsers,
    };

    saveSupplierDetails(body, token, (res) => {
      hideSupplier();
      clearS();
      setCount(prev => prev + 1);
      showSuccessToast(toast, isAddingS ? "Supplier Created": "Supplier Updated");
      setIsAddingS(false);
    }, (e) => {
      hideSupplier();
      setIsAddingS(false);
      clearS();
      if (e?.response?.status === 401) {
        showErrorToastNullable(toast, "Session timeout! Please logout and login again");
      } 
      else if (e?.response?.status === 500) {
        showErrorToastNullable(toast, "Error while saving records");
      } else {
        showErrorToastNullable(toast, e?.response?.data?.Message);
      }
    });
  };

  const saveManufacturer = () => {
    let body = {
      id: isAddingM ? null : mId,
      name: manufacturerName,
      startDate : formatDate(startDate),
      endDate: formatDate(endDate),
      supplierGroupId: selectedSupplierGroup != null ? selectedSupplierGroup : [],
      suppliers: selectedSuppliers,
      isActive: isAddingM ? true : isManufacturerActive,
      CreatedBy: "Admin@bunzl.com",
      showInactive: showInactiveUsers,
    };

    saveManufacturerDetails(body, token, (res) => {
      hideManufacturer();
      setIsAddingM(false);
      clearM();
      setCount(prev => prev + 1);
      showSuccessToast(toast, isAddingM ? "Manufacturer Created" : "Manufacturer Updated");
    }, (e) => {
      if (e?.response?.status === 401) {
        showErrorToastNullable(toast, "Session timeout! Please logout and login again");
      }
      else if (e?.response?.status === 500) {
        showErrorToastNullable(toast, "Error while saving records");
      } else {
        showErrorToastNullable(toast, e?.response?.data?.Message);
      }
    });
  };
  
  useEffect(() => {
    console.log("startdate",startDate);
  }, [startDate]);

  const formatDate = (input) => {
    if (input == null || input === "") return null;
    return input.getFullYear() + "-" +
      (input.getMonth()+1) + "-" + 
      input.getDate() +
      "T00:00:00";
  };

  const supplierGroupDialogFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          style={{ backgroundColor: "#003e7e" }}
          icon="pi pi-times"
          onClick={hideSupplierGroup}
        />
        <Button
          label="Save"
          style={{ backgroundColor: "#003e7e" }}
          icon="pi pi-check"
          onClick={saveSupplierGroup}
          disabled={!supplierGroupName}
        />
      </>
    );
  };

  const supplierDialogFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          style={{ backgroundColor: "#003e7e" }}
          icon="pi pi-times"
          onClick={hideSupplier}
        />
        <Button
          label="Save"
          style={{ backgroundColor: "#003e7e" }}
          icon="pi pi-check"
          onClick={saveSupplier}
          disabled={!supplierName || !selectedSupplierGroup }
        />
      </>
    );
  };

  const manufacturerDialogFooter = () => {
    return (
      <>
        <Button
          label="Cancel"
          style={{ backgroundColor: "#003e7e" }}
          icon="pi pi-times"
          onClick={hideManufacturer}
        />
        <Button
          label="Save"
          style={{ backgroundColor: "#003e7e" }}
          icon="pi pi-check"
          onClick={saveManufacturer}
          disabled={!manufacturerName || (!isAddingM && !validEndDate)}
        />
      </>
    );
  };

  const sgRowExpansionTemplate = (data) => {
    return (
      <div className="flex gap-2">
       <DataTable
        className="w-50"
        dataKey="id"
        value={data.suppliers}
        removableSort
      >
        <Column 
          field="name" 
          header="Supplier Names" 
          sortable
        ></Column>
      </DataTable>
      <DataTable
        className="w-50"
        dataKey="manufacturerId"
        value={data.manufacturers}
        removableSort
      >
        <Column 
          field="manufacturerName" 
          header="Manufacturer Name" 
          sortable
        ></Column>
      </DataTable>
    </div>
    );
  };
  
  const sRowExpansionTemplate = (data) => {
    return (
      <DataTable
        dataKey="manufacturerId"
        value={data.manufacturers}
        removableSort
      >
        <Column 
          field="manufacturerName"
          header="Manufacturer Name" 
          sortable
        ></Column>
      </DataTable>
    );
  };

  const mRowExpansionTemplate = (data) => {
    return (
      <div className="flex gap-2">
        <DataTable
          style={{ width: "33%" }}
          dataKey="id"
          value={data.supplierGroups}
          removableSort
        >
          <Column 
            field="name" 
            header="Supplier Groups" 
            sortable
          ></Column>
        </DataTable>
        <DataTable
          style={{ width: "33%" }}
          dataKey="id"
          value={data.suppliers}
          removableSort
        >
          <Column 
            field="name" 
            header="Supplier Names" 
            sortable
          ></Column>
        </DataTable>
        <DataTable
          style={{ width: "33%" }}
          dataKey="id"
          value={data.users}
          removableSort
        >
          <Column 
            field="email" 
            header="Contact Info" 
            sortable
          ></Column>
        </DataTable>
      </div>
    );
  };

  const sgAllowExpansion = (data) => {
    return data.suppliers?.length > 0 || data.manufacturers?.length > 0;
  };

  const sAllowExpansion = (data) => {
    return data.manufacturers?.length > 0;
  };

  const mAllowExpansion = (data) => {
    return data.supplierGroups?.length > 0 || data.suppliers?.length > 0 || data.users?.length > 0;
  };

  const concatSupplierNames = (data) => {
    return data.suppliers?.map(supplier => supplier.name).join(', ') || '';
  };

  const concatManufacturerNames = (data) => {
    return data.manufacturers?.map(m => m.manufacturerName).join(', ') || '';
  };

  const concatSupplierGroups = (data) => {
    return data.supplierGroups?.map(sg => sg.name).join(', ') || '';
  }

  const concatEmails = (data) => {
    return data.users?.map(u => u.email).join(', ') || '';
  }
  
  const isActiveTemplate = (data) => {
    return data.isActive === true ? "Active" : data.isActive === false ? "In-active" : "";
  };

  return (
    <>
    <Toast ref={toast} />
    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
      <TabPanel header="Supplier Groups">
        <DataTable
          ref={sgdt}
          value={supplierGroupList}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Supplier/Supplier Groups"
          header={header}
          loading={isSgLoading}
          filters={sgFilters}
          globalFilterFields={['name', 'SupplierNames', 'ManufacturerNames', 'isActiveLabel']}
          removableSort
          scrollable
          scrollHeight="50vh"
          expandedRows={expandedSgRows}
          onRowToggle={(e) => setExpandedSgRows(e.data)}
          rowExpansionTemplate={sgRowExpansionTemplate}
        >
          <Column expander={sgAllowExpansion}></Column>
          <Column
            field="name"
            header="Supplier Group Name"
            sortable
          ></Column>
          <Column
            field="SupplierNames"
            header="Supplier Names"
            sortable
          ></Column>
          <Column
            field="ManufacturerNames"
            header="Manufacturer Name"
            sortable
          ></Column>
          <Column
            field="isActiveLabel"
            header="Status"
            sortable
          ></Column>
          <Column
            header="Action"
            body={viewDetailsTemplate}
          ></Column>
        </DataTable>
      </TabPanel>
      <TabPanel header="Suppliers">
        <DataTable
          ref={sdt}
          value={supplierList}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Supplier/Supplier Groups"
          header={header}
          loading={isSLoading}
          filters={sFilters}
          globalFilterFields={['name', 'supplierGroup.name', 'ManufacturerNames', 'nominatorName', 'isActiveLabel']}
          removableSort
          scrollable
          scrollHeight="50vh"
          expandedRows={expandedSRows}
          onRowToggle={(e) => setExpandedSRows(e.data)}
          rowExpansionTemplate={sRowExpansionTemplate}
        >
          <Column expander={sAllowExpansion}></Column>
          <Column
            field="name"
            header="Supplier Name"
            sortable
          ></Column>
          <Column
            field="supplierGroup.name"
            header="Supplier Group"
            sortable
          ></Column>
          <Column
            field="ManufacturerNames"
            header="Manufacturer Name"
            sortable
          ></Column>
          <Column
            header="Nominated By"
            field="nominatorName"
            sortable
            hidden
          ></Column>
          <Column
            field="isActiveLabel"
            header="Status"
            sortable
          ></Column>
          <Column
            header="Action"
            body={viewDetailsTemplate}
          ></Column>
        </DataTable>
      </TabPanel>
      <TabPanel header="Manufacturers">
        <DataTable
          ref={mdt}
          value={manufacturerList}
          dataKey="manufacturerId"
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Manufacturers"
          header={header}
          loading={isMLoading}
          filters={mFilters}
          globalFilterFields={['manufacturerName', 'SupplierGroupNames', 'SupplierNames', 'Emails','isActiveLabel']}
          removableSort
          scrollable
          scrollHeight="50vh"
          expandedRows={expandedMRows}
          onRowToggle={(e) => setExpandedMRows(e.data)}
          rowExpansionTemplate={mRowExpansionTemplate}

        >
          <Column expander={mAllowExpansion}></Column>
          <Column
            field="manufacturerName"
            header="Manufacturer Name"
            sortable
          ></Column>
          <Column 
            field="SupplierGroupNames"
            header="Supplier Group" 
            sortable
          ></Column>
          <Column
            field="SupplierNames"
            header="Supplier Name"
            sortable
          ></Column>
          <Column
            field="Emails"
            header="Contact Info"
            hidden
          ></Column>
          <Column
            field="startDate"
            header="Start Date"
            hidden
          ></Column>
          <Column
            field="endDate"
            header="End Date"
            hidden
          ></Column>
          <Column
            field="isActiveLabel"
            header="Status"
            sortable
          ></Column>
          <Column
            header="Action"
            body={viewDetailsTemplate}
          ></Column>
        </DataTable>
      </TabPanel>
    </TabView>
    <Dialog
      visible={supplierGroupDialog}
      header="Supplier Group Details"
      modal
      onHide={hideSupplierGroup}
      footer={supplierGroupDialogFooter}
      style={{ width: "24rem" }}
    >
      <div className="supplierManagementDialog">
        <div className="supplierManagementField">
          <label htmlFor="SupplierName" className="font-bold">
          Supplier Group Name<span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="SupplierGroupName"
            required
            value={supplierGroupName}
            onChange={(e) => setSupplierGroupName(e.target.value)}
          />
        </div>
        <div className="supplierManagementField">
          <label htmlFor="SuppliersList" className="font-bold">
          List Of Suppliers
          </label>
          <MultiSelect
            id="SuppliersList"
            value={selectedSuppliers}
            options={supplierList}
            onChange={(e) => setSelectedSuppliers(e.value)}
            placeholder="Suppliers List"
            optionLabel="name"
            optionValue="id"
            display="chip"
            filter
          />  
        </div>
        <div className="supplierManagementField">
          <label htmlFor="ManufacturersList" className="font-bold">
          List Of Manufacturers
          </label>
          <MultiSelect
            id="ManufacturersList"
            value={selectedManufacturers}
            options={manufacturerList}
            onChange={(e) => setSelectedManufacturers(e.value)}
            placeholder="Manufacturers List"
            optionLabel="manufacturerName"
            optionValue="manufacturerId"
            display="chip"
            filter
          />  
        </div>
      
        {!isAddingSg &&
        <div className="flex align-items-center isSupplierActive-container">
          <div>
            <RadioButton 
              name="isSupplierGroupActive" 
              inputId="supplierGroupIsActive" 
              value={true} 
              onChange={(e) => setIsSupplierGroupActive(e.value)}
              checked={isSupplierGroupActive === true}/>
            <label htmlFor="supplierGroupIsActive" className="p-radiobutton-label">Active</label> 
          </div>
          <div>
            <RadioButton 
              name="isSupplierGroupActive" 
              inputId="supplierGroupIsNotActive" 
              value={false} 
              onChange={(e) => setIsSupplierGroupActive(e.value)}
              checked={isSupplierGroupActive === false}/>
            <label htmlFor="supplierGroupIsNotActive" className="p-radiobutton-label">Inactive</label> 
          </div>
        </div>}
      </div>
    </Dialog>
    <Dialog
      visible={supplierDialog}
      header="Supplier Details"
      modal
      onHide={hideSupplier}
      footer={supplierDialogFooter}
      style={{ width: "24rem" }}
    >
      <div className="supplierManagementDialog">
        <div className="supplierManagementField">
          <label htmlFor="SupplierName" className="font-bold">
          Supplier Name<span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="SupplierName"
            required
            value={supplierName}
            onChange={(e) => setSupplierName(e.target.value)}
          />
        </div>
        <div className="supplierManagementField">
          <label htmlFor="SupplierGroups" className="font-bold">
          List Of Supplier Groups<span style={{ color: "red" }}>*</span>
          </label>
          <Dropdown
            id="SupplierGroups"
            required
            value={selectedSupplierGroup}
            options={supplierGroupList}
            onChange={(e) => setSelectedSupplierGroup(e.value)}
            placeholder="Supplier Group List"
            showClear
            optionLabel="name"
            optionValue="id"
            filter
          />  
        </div>  
        <div className="supplierManagementField">
          <label htmlFor="ManufacturersList" className="font-bold">
          List Of Manufacturers
          </label>
          <MultiSelect
            id="ManufacturersList"
            value={selectedManufacturers}
            options={manufacturerList}
            onChange={(e) => setSelectedManufacturers(e.value)}
            placeholder="Manufacturers List"
            optionLabel="manufacturerName"
            optionValue="manufacturerId"
            display="chip"
            filter
          />  
        </div>

        <div className="supplierManagementField">
          <label htmlFor="nominatedBy" className="font-bold">Nominated By</label>
          <Dropdown
            id="nominatedBy"
            value={nominatedBy}
            options={nominatedByList}
            onChange={(e) => setNominatedBy(e.value)}
            placeholder="Nominator"
            showClear
            optionLabel="name"
            optionValue="id"
            display="chip"
          >
          </Dropdown>
        </div>
        {!isAddingS &&
        <div className="flex align-items-center isSupplierActive-container">
          <div>
            <RadioButton 
              name="isSupplierActive" 
              inputId="supplierIsActive" 
              value={true} 
              onChange={(e) => setIsSupplierActive(e.value)}
              checked={isSupplierActive === true}/>
            <label htmlFor="supplierIsActive" className="p-radiobutton-label">Active</label> 
          </div>
          <div>
            <RadioButton 
              name="isSupplierActive" 
              inputId="supplierIsNotActive" 
              value={false} 
              onChange={(e) => setIsSupplierActive(e.value)}
              checked={isSupplierActive === false}/>
            <label htmlFor="supplierIsNotActive" className="p-radiobutton-label">Inactive</label> 
          </div>
        </div>}
      </div>
    </Dialog>
    <Dialog
      visible={manufacturerDialog}
      header="Manufacturer Details"
      modal
      onHide={hideManufacturer}
      footer={manufacturerDialogFooter}
      style={{ width: "24rem" }}
    >
      <div className="supplierManagementDialog">
        <div className="supplierManagementField">
          <label htmlFor="ManufacturerName" className="font-bold">
          Manufacturer Name<span style={{ color: "red" }}>*</span>
          </label>
          <InputText
            id="ManufacturerName"
            required
            value={manufacturerName}
            onChange={(e) => setManufacturerName(e.target.value)}
          />
        </div>
        <div className="supplierManagementField">
          <label htmlFor="SupplierGroups" className="font-bold">
          List Of Supplier Groups
          </label>
          <MultiSelect
            id="SupplierGroups"
            value={selectedSupplierGroup}
            options={supplierGroupList}
            onChange={(e) => setSelectedSupplierGroup(e.value)}
            placeholder="Supplier Group List"
            showClear
            optionLabel="name"
            optionValue="id"
            display="chip"
            filter
          />  
        </div>
        <div className="supplierManagementField">
          <label htmlFor="SuppliersList" className="font-bold">
          List Of Suppliers
          </label>
          <MultiSelect
            id="SuppliersList"
            value={selectedSuppliers}
            options={supplierShortList}
            onChange={(e) => setSelectedSuppliers(e.value)}
            placeholder="Suppliers List"
            optionLabel="name"
            optionValue="id"
            display="chip"
            filter
          />  
        </div>
        <div className="supplierManagementField">
          <label htmlFor="startDate" className="font-bold">
              Start Date
            </label>
            <Calendar 
              id="startDate"
              value={startDate} 
              onChange={(e) => setStartDate(e.value)}              
              placeholder="Select Start Date"
              showIcon 
              showButtonBar
            />
        </div>
        {!isAddingM &&
        <div className="supplierManagementField">
          <label htmlFor="endDate" className="font-bold">
              End Date
            </label>
            <Calendar 
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.value)} 
              placeholder="Select End Date"
              showIcon 
              minDate={minDate}
              showButtonBar
            />
        </div>
        }

        {!isAddingM &&
        <div className="flex align-items-center isSupplierActive-container">
          <div>
            <RadioButton 
              name="isManufacturerActive" 
              inputId="manufacturerIsActive" 
              value={true} 
              onChange={(e) => setIsManufacturerActive(e.value)}
              checked={isManufacturerActive === true}/>
            <label htmlFor="manufacturerIsActive" className="p-radiobutton-label">Active</label> 
          </div>
          <div>
            <RadioButton 
              name="isManufacturerActive" 
              inputId="manufacturerIsNotActive" 
              value={false} 
              onChange={(e) => setIsManufacturerActive(e.value)}
              checked={isManufacturerActive === false}/>
            <label htmlFor="manufacturerIsNotActive" className="p-radiobutton-label">Inactive</label> 
          </div>
        </div>}
      </div>
    </Dialog>
  </>
  );
}